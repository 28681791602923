import actions from './actions';

const {
  GET_PRODUCTS,
  GET_PRODUCTS_ERR,
  GET_PRODUCTS_BEGIN,
  CREATE_PRODUCT,
  CREATE_PRODUCT_BEGIN,
  CREATE_PRODUCT_ERR,
  GET_CATEGORIES,
  GET_CATEGORIES_ERR,
  GET_CATEGORIES_BEGIN,
  GET_OPTIONS,
  GET_OPTIONS_ERR,
  GET_OPTIONS_BEGIN,
} = actions;
const initState = {
  products: [],
  page: 1,
  totalResults: 0,
  limit: 0,
  loading: false,
  error: null,
  categories: [],
  options: [],
};

/**
 *
 * @todo impure state mutation/explaination
 */
const ProductsReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_PRODUCTS:
      return {
        ...state,
        loading: false,
        products: data.results,
        totalResults: data.totalResults,
        limit: data.limit,
        page: data.page,
      };
    case GET_PRODUCTS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_PRODUCTS_BEGIN:
      return {
        ...state,
        error: null,
        products: [],
        totalResults: initState.totalResults,
        limit: initState.limit,
        page: initState.page,
        loading: true,
      };
    case CREATE_PRODUCT:
      return {
        ...state,
        loadingCreate: false,
      };
    case CREATE_PRODUCT_BEGIN:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_PRODUCT_ERR:
      return {
        ...state,
        loadingCreate: false,
        error: err,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: data.results,
        error: err,
        loading: false,
      };
    case GET_CATEGORIES_BEGIN:
      return {
        ...state,
        categories: [],
        error: null,
        loading: false,
      };
    case GET_CATEGORIES_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_OPTIONS:
      return {
        ...state,
        options: data.results,
        error: err,
        loading: false,
      };
    case GET_OPTIONS_BEGIN:
      return {
        ...state,
        options: [],
        error: null,
        loading: false,
      };
    case GET_OPTIONS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };

    default:
      return state;
  }
};
export default ProductsReducer;
