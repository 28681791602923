import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { theme } from '../../config/theme/themeVariables';

const Dashboard = () => {
  const userLogged = useSelector(state => state.auth.login);
  const { path } = useRouteMatch();
  return (
    <>
      <PageHeader ghost title="Inicio" buttons={[]} />
      <Main>
        <Cards
          bodyStyle={{
            backgroundImage: `linear-gradient(90deg, ${theme['primary-color']}, ${theme['secondary-color']})`,
            borderRadius: `0 0 10px 10px`,
            display: 'flex',
            justifyContent: 'space-around',
          }}
          title="Acciones Principales"
          size="large"
        >
          {userLogged?.user?.role !== 'pantalla' ? (
            <Link to={`${path}/pos`}>
              <Button size="default" type="primary" ghost>
                <FeatherIcon icon="shopping-cart" size={14} />
                Punto de Venta
              </Button>
            </Link>
          ) : null}
          <Link to={`${path}/ordenes`}>
            <Button size="default" type="secondary" ghost>
              <FeatherIcon icon="shopping-bag" size={14} />
              Ordenes Pendientes
            </Button>
          </Link>
          {userLogged?.user?.role === 'cajero' ? (
            <Link to={`${path}/caja`}>
              <Button size="default" type="dark" ghost>
                <FeatherIcon icon="dollar-sign" size={14} />
                Movmientos Caja
              </Button>
            </Link>
          ) : null}
        </Cards>
      </Main>
    </>
  );
};

export default Dashboard;
