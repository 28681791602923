import actions from './actions';

const { GET_CARDS, GET_CARD, GET_CARDS_ERR, GET_CARDS_BEGIN, GET_CARD_BEGIN, RESET_CARD } = actions;
const initState = {
  cards: [],
  card: null,
  page: 1,
  totalResults: 0,
  limit: 0,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const CardsReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CARDS:
      return {
        ...state,
        loading: false,
        cards: data.results,
        totalResults: data.totalResults,
        limit: data.limit,
        page: data.page,
      };
    case GET_CARD:
      return {
        ...state,
        loading: false,
        card: data,
        totalResults: data.totalResults,
        limit: data.limit,
        page: data.page,
      };
    case GET_CARDS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_CARDS_BEGIN:
      return {
        ...state,
        error: null,
        cards: [],
        totalResults: initState.totalResults,
        limit: initState.limit,
        page: initState.page,
        loading: true,
      };
    case GET_CARD_BEGIN:
      return {
        ...state,
        error: null,
        card: [],
        totalResults: initState.totalResults,
        limit: initState.limit,
        page: initState.page,
        loading: true,
      };
    case RESET_CARD:
      return {
        ...state,
        error: null,
        card: [],
        totalResults: initState.totalResults,
        limit: initState.limit,
        page: initState.page,
      };

    default:
      return state;
  }
};
export default CardsReducer;
