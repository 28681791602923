import React, { useEffect, useState, useRef } from 'react';
import { Tag, Row, Col, Modal, message } from 'antd';
import Heading from '../heading/heading';
import { RightCircleFilled } from '@ant-design/icons';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import SummaryPayment from '../../components/summaryPayment/summaryPayment';
import { OrderCardStyled, ListGroup, ItemStyle, ItemIcon } from './style';
import { DataService } from '../../config/dataService/dataService';
import { dateInHoursFromId, calculateTimeElapsed, getProductString } from '../../utility/utility';
import { API } from '../../config/api/index';
import { theme } from '../../config/theme/themeVariables';
// import { printOrder } from '../../utility/printerUtils';
import { useDispatch } from 'react-redux';
import { resetCardAction } from '../../redux/cards/actionCreator';

const canvasDefaultHeight = 384;
const canvasDefaultWidth = 213;

const getEmojiByRol = role => {
  switch (role) {
    case 'drive-thru':
      return (
        <span className="icon-blue">
          <FontAwesome name="car" style={{ marginRight: '4px', fontSize: '18px' }} />
        </span>
      );
    // case 'tablet':
    //   return (
    //     <span className="icon-blue">
    //       <FontAwesome name="tablet" style={{ marginRight: '4px', fontSize: '18px' }} />
    //     </span>
    //   );
    default:
      return (
        <span className="icon primary">
          <FontAwesome name="user" style={{ marginRight: '4px', fontSize: '18px' }} />
        </span>
      );
  }
};

const getStatusPay = statusType => {
  switch (statusType) {
    case 'Por Pagar':
      return 'warning';
    case 'Pagada':
      return 'success';
    default:
      return 'warning';
  }
};

const getStatusOrder = statusType => {
  switch (statusType) {
    case 'Entregada':
      return 'success';
    case 'Pendiente':
    default:
      return 'warning';
  }
};

const OrderCard = ({ order, clients, onSuccessChangeOrder, showActions, ipName }) => {
  const {
    id,
    total,
    subtotal,
    customerName = 'Sin Nombre',
    statusPay = 'Por Pagar',
    statusOrder = 'Pendiente',
    createdBy,
    products = [],
    note,
    orderUserType = 'cajero',
    change = 0,
  } = order;

  const dispatch = useDispatch();

  const userName = createdBy?.name || 'Sin Nombre';

  const canvas = useRef(null);

  const [state, setState] = useState({
    visible: false,
    loading: false,
    statusPayOrder: statusPay,
    statusOrder: statusOrder,
    amount: 0,
    paymentType: 'Efectivo',
    id_card: null,
    coinpurse: null,
    montoRecarga: null,
    cardQR: '',
    monedero: false,
    totalDiscountChanged: total,
    discountSelected: { discount: 0, discountMember: '' },
    memberCardAmount: 0,
    change: change,
  });

  const printReceipt = orderPayed => {
    // printOrder(orderPayed, ipName);
    console.log('Print Not Supported');
  };

  const onChangeDiscount = discountSelected => {
    setState({
      ...state,
      discountSelected,
    });
  };

  const onChangePayWithMemberCard = memberCardAmount => {
    setState({
      ...state,
      memberCardAmount,
    });
  };

  const onChangeAmount = amount => {
    setState({
      ...state,
      amount,
    });
  };

  const onChangePaymentType = paymentType => {
    setState({
      ...state,
      amount: 0,
      paymentType,
    });
  };
  const onChangecardQR = (idcard, amount, cardQR, coinpurse, montoRecarga) => {
    setState({
      ...state,
      id_card: idcard,
      amount,
      cardQR,
      coinpurse,
      montoRecarga,
      monedero: true,
    });
  };
  const handleOk = async () => {
    setState({
      ...state,
      loading: true,
    });
    const discountObject = state.discountSelected.discount
      ? { discount: state.discountSelected.discount, discountMember: state.discountSelected.discountMember }
      : {};

    const totalWithDiscount = subtotal - (discountObject.discount || 0);
    if (state.amount === null || state.amount === undefined) {
      setState({ ...state, loading: false });
      message.warn('Debes capturar el monto a cobrar');
    } else {
      DataService.patch(`${API.pos.orders}/${id}/pay`, {
        ...discountObject,
        amount: state.amount,
        paymentType: state.paymentType,
        cardId: state.id_card || null,
        memberCardAmount: state.memberCardAmount,
      })
        .then(data => {
          setState({
            ...state,
            loading: false,
            visible: false,
            statusPayOrder: 'Pagada',
            totalDiscountChanged: totalWithDiscount,
            change: data?.data?.change,
            id_card: null,
          });
          dispatch(resetCardAction());
        })
        .catch(err => {
          setState({ ...state, loading: false });
          // message.error(err?.message || 'Error al intentar actualizar la orden');
        });
    }
  };

  const handleCancel = () => {
    if (state.monedero === true) {
      setState({
        ...state,
        id_card: null,
        amount: null,
        cardQR: null,
        coinpurse: null,
        montoRecarga: null,
        monedero: false,
        visible: false,
      });
    } else {
      setState({
        ...state,
        visible: false,
      });
    }
  };

  const showModal = () => {
    setState({
      ...state,
      visible: true,
    });
  };

  const onClickChangeOrder = async _statusOrder => {
    setState({
      ...state,
      loading: true,
    });

    if (_statusOrder === 'Entregada' && state.statusPayOrder !== 'Pagada') {
      message.info('Debes Capturar el pago de la order antes de entregar');
    } else {
      DataService.patch(`${API.pos.orders}/${id}/statusOrder`, {
        statusOrder: _statusOrder,
      })
        .then(data => {
          setState({ ...state, loading: false, visible: false, statusOrder: _statusOrder });
          onSuccessChangeOrder();
        })
        .catch(err => {
          setState({ ...state, loading: false });
          // message.error(err?.message || 'Error al intentar actualizar la orden');
        });
    }
  };

  const [timeElapsed, setTimeElapsed] = useState(calculateTimeElapsed(id));

  const colorDriveThru = () => {
    let color = theme['card-drive-thru'];
    const minutes = timeElapsed.split(':')[1];
    if (minutes >= 2) color = theme['warning-light-color'];
    if (minutes >= 5) color = theme['danger-color'];

    return color;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeElapsed(calculateTimeElapsed(id));
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <OrderCardStyled>
      <div key={id} className="card user-card user-card-primary">
        <Cards
          headless
          bodyStyle={{
            backgroundColor: orderUserType === 'drive-thru' ? colorDriveThru() : theme[`card-${orderUserType}`],
            borderRadius: '10px',
          }}
        >
          <canvas hidden ref={canvas} width={canvasDefaultHeight} height={canvasDefaultWidth} />
          <Modal
            type="info"
            title="Pagar Orden"
            visible={state.visible}
            onOk={handleOk}
            confirmLoading={state.loading}
            onCancel={handleCancel}
            okText="Pagar"
            cancelText="Cancelar"
          >
            <SummaryPayment
              clients={clients}
              subtotal={subtotal}
              products={products.length}
              amount={state.amount}
              monedero={state.monedero}
              paymentType={state.paymentType}
              memberCardAmount={state.memberCardAmount}
              onChangeAmount={onChangeAmount}
              onChangeDiscount={onChangeDiscount}
              onChangePayWithMemberCard={onChangePayWithMemberCard}
              onChangePaymentType={onChangePaymentType}
              onChangecardQR={onChangecardQR}
            />
          </Modal>
          <figcaption>
            <div className="card__content card__content-primary">
              <Heading className="card__name" as="h2">
                <strong>{customerName}</strong>
              </Heading>
              <ItemIcon className="list-single">
                <p className="card__name" as="h4">
                  Capturada por {getEmojiByRol(orderUserType)}: <strong>{userName} </strong>
                </p>
              </ItemIcon>
              <p className="card__name" as="h4">
                Hora Captura: <strong>{dateInHoursFromId(id)}</strong> | <strong>{timeElapsed}</strong>
              </p>
            </div>
            <ListGroup>
              {products.map(product => (
                <ItemStyle key={product._id} className="list-single">
                  <span className="icon">
                    <RightCircleFilled size={14} />
                  </span>
                  <span>
                    <strong>{product.quantity}</strong> / {getProductString(product)}
                  </span>
                </ItemStyle>
              ))}
            </ListGroup>
            {note ? <p className="card__designation">NOTAS: {note}</p> : null}
            {showActions ? (
              <div className="card__actions">
                {state.statusOrder === 'Finalizada' ? (
                  React.null
                ) : state.statusOrder === 'Entregada' && state.statusPayOrder === 'Pagada' ? (
                  <Button size="default" onClick={() => onClickChangeOrder('Finalizada')} type="success">
                    <FontAwesome name="thumbs-o-up" style={{ marginRight: '4px', fontSize: '14px' }} />
                    Finalizar
                  </Button>
                ) : (
                  <>
                    <Button
                      size="default"
                      onClick={showModal}
                      disabled={state.statusPayOrder === 'Pagada'}
                      type="primary"
                    >
                      <FontAwesome name="money" style={{ marginRight: '4px', fontSize: '14px' }} />
                      Cobrar
                    </Button>
                    <Button
                      size="default"
                      type="success"
                      disabled={state.statusOrder === 'Entregada'}
                      onClick={async () => {
                        await onClickChangeOrder('Entregada');
                        printReceipt(order);
                      }}
                    >
                      <FontAwesome name="check" style={{ marginRight: '4px', fontSize: '14px' }} />
                      Entregar
                    </Button>
                  </>
                )}
              </div>
            ) : null}

            <div className="card__info">
              <Row gutter={15}>
                <Col xs={8}>
                  <div className="info-single">
                    Total
                    <Heading className="info-single__title" as="h3">
                      ${state.totalDiscountChanged}
                    </Heading>
                  </div>
                </Col>

                <Col xs={8}>
                  <div className="info-single">
                    {state.statusPayOrder === 'Pagada' ? (
                      <>
                        <span>Cambio: </span>
                        <Heading className="info-single__subtitle">${state.change}</Heading>
                      </>
                    ) : (
                      <span>Estado Pago</span>
                    )}
                    <Tag className={getStatusPay(state.statusPayOrder)}>{state.statusPayOrder}</Tag>
                  </div>
                </Col>
                <Col xs={8}>
                  <div className="info-single">
                    <span>Estado Orden</span>
                    <Tag className={getStatusOrder(state.statusOrder)}>{state.statusOrder}</Tag>
                  </div>
                </Col>
              </Row>
            </div>
          </figcaption>
        </Cards>
      </div>
    </OrderCardStyled>
  );
};

OrderCard.defaultProps = {
  order: {},
  clients: [],
  onSuccessChangeOrder: console.log,
  showActions: true,
  ipName: '127.0.0.1',
};

OrderCard.propTypes = {
  order: PropTypes.object,
  clients: PropTypes.array,
  onSuccessChangeOrder: PropTypes.func,
  showActions: PropTypes.bool,
  ipName: PropTypes.string,
};

export default OrderCard;
