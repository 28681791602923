import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Table } from 'antd';
import { PageHeader } from '../page-headers/page-headers';
import { Main, TableWrapper } from '../../container/styled';
import { Cards } from '../cards/frame/cards-frame';
import { dateFromObjectId, getFormatedSecondsToTime } from '../../utility/utility';

const CortesTable = ({ pageNumber, onChangePageNumber }) => {
  const { cortes, total } = useSelector(state => {
    return {
      cortes: state.cortes.cortes,
      total: state.cortes.totalResults,
    };
  });

  const handleOnChangePage = params => {
    onChangePageNumber(params);
  };

  const dataSource = [];
  if (cortes.length) {
    cortes.map((value, key) => {
      const {
        id,
        status,
        montoFisico,
        montoTeorico,
        montoTarjetaTeorico,
        montoEfectivoTeorico,
        totalVentasCorte,
        fondoInicial,
        userId,
        vasosCierreCalienteGrande,
        vasosCierreCalienteMediano,
        vasosCierreFrioGrande,
        vasosCierreFrioMediano,
        vasosInicialCalienteGrande,
        vasosInicialCalienteMediano,
        vasosInicialFrioGrande,
        vasosInicialFrioMediano,
        ventasVasoCalienteGrande,
        ventasVasoCalienteMediano,
        ventasVasoFrioGrande,
        ventasVasoFrioMediano,
        ventasVasoTotal,
        tiempoPromedio = 0,
        tiempoMenor = 0,
        tiempoMayor = 0,
        totalVentasRecargas = 0,
        totalTarjetaRecargas = 0,
        totalEfectivoRecargas = 0,
      } = value;

      return dataSource.push({
        key: key + 1,
        id: <span className="order-id">{id.substring(8)}</span>,
        status: <span className={`status ${status === 'Procesado' ? 'Success' : 'warning'}`}>{status}</span>,
        userId: <span className="ordered-amount">{userId?.name}</span>,
        montoFisico: <span className="ordered-amount">${montoFisico}</span>,
        fondoInicial: <span className="ordered-amount">${fondoInicial}</span>,
        montoTeorico: <span className="ordered-amount">${montoTeorico}</span>,
        montoTarjetaTeorico: <span className="ordered-amount">${montoTarjetaTeorico}</span>,
        montoEfectivoTeorico: <span className="ordered-amount">${montoEfectivoTeorico}</span>,
        totalVentasCorte: <span className="ordered-amount">{totalVentasCorte}</span>,
        vasosInicialCalienteGrande: <span className="ordered-amount">{vasosInicialCalienteGrande}</span>,
        vasosInicialCalienteMediano: <span className="ordered-amount">{vasosInicialCalienteMediano}</span>,
        vasosInicialFrioGrande: <span className="ordered-amount">{vasosInicialFrioGrande}</span>,
        vasosInicialFrioMediano: <span className="ordered-amount">{vasosInicialFrioMediano}</span>,
        vasosCierreCalienteGrande: <span className="ordered-amount">{vasosCierreCalienteGrande}</span>,
        vasosCierreCalienteMediano: <span className="ordered-amount">{vasosCierreCalienteMediano}</span>,
        vasosCierreFrioGrande: <span className="ordered-amount">{vasosCierreFrioGrande}</span>,
        vasosCierreFrioMediano: <span className="ordered-amount">{vasosCierreFrioMediano}</span>,
        ventasVasoTotal: <span className="ordered-amount">{ventasVasoTotal}</span>,
        ventasVasoCalienteGrande: <span className="ordered-amount">{ventasVasoCalienteGrande}</span>,
        ventasVasoCalienteMediano: <span className="ordered-amount">{ventasVasoCalienteMediano}</span>,
        ventasVasoFrioGrande: <span className="ordered-amount">{ventasVasoFrioGrande}</span>,
        ventasVasoFrioMediano: <span className="ordered-amount">{ventasVasoFrioMediano}</span>,
        totalVentasRecargas: <span className="ordered-amount">${totalVentasRecargas}</span>,
        totalTarjetaRecargas: <span className="ordered-amount">${totalTarjetaRecargas}</span>,
        totalEfectivoRecargas: <span className="ordered-amount">${totalEfectivoRecargas}</span>,
        tiempoPromedio: <span className="ordered-amount">{getFormatedSecondsToTime(Math.floor(tiempoPromedio))}</span>,
        tiempoMenor: <span className="ordered-amount">{getFormatedSecondsToTime(tiempoMenor)}</span>,
        tiempoMayor: <span className="ordered-amount">{getFormatedSecondsToTime(tiempoMayor)}</span>,
        date: <span className="ordered-date">{dateFromObjectId(id)}</span>,
      });
    });
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Monto Cierre',
      dataIndex: 'montoFisico',
      key: 'montoFisico',
    },

    {
      title: 'Fondo Inicial',
      dataIndex: 'fondoInicial',
      key: 'fondoInicial',
    },
    {
      title: 'Total Ventas',
      dataIndex: 'montoTeorico',
      key: 'montoTeorico',
    },
    {
      title: 'Total Tarjeta',
      dataIndex: 'montoTarjetaTeorico',
      key: 'montoTarjetaTeorico',
    },
    {
      title: 'Total Efectivo',
      dataIndex: 'montoEfectivoTeorico',
      key: 'montoEfectivoTeorico',
    },
    {
      title: 'Número de Ventas',
      dataIndex: 'totalVentasCorte',
      key: 'totalVentasCorte',
    },
    {
      title: 'Vaso Ini Ca. G',
      dataIndex: 'vasosInicialCalienteGrande',
      key: 'vasosInicialCalienteGrande',
    },
    {
      title: 'Vaso Ini Ca. M',
      dataIndex: 'vasosInicialCalienteMediano',
      key: 'vasosInicialCalienteGrande',
    },
    {
      title: 'Vaso Ini Fri. G',
      dataIndex: 'vasosInicialFrioGrande',
      key: 'vasosInicialFrioGrande',
    },
    {
      title: 'Vaso Ini Fr. M',
      dataIndex: 'vasosInicialFrioMediano',
      key: 'vasosInicialFrioGrande',
    },
    {
      title: 'Vasos Cierre Ca. G',
      dataIndex: 'vasosCierreCalienteGrande',
      key: 'vasosCierreCalienteGrande',
    },
    {
      title: 'Vasos Cierre Ca. M',
      dataIndex: 'vasosCierreCalienteMediano',
      key: 'vasosCierreCalienteMediano',
    },
    {
      title: 'Vasos Cierre Fr. G',
      dataIndex: 'vasosCierreFrioGrande',
      key: 'vasosCierreFrioGrande',
    },
    {
      title: 'Vasos Cierre Fr. M',
      dataIndex: 'vasosCierreFrioMediano',
      key: 'vasosCierreFrioMediano',
    },
    {
      title: 'Vasos Vendidos Total',
      dataIndex: 'ventasVasoTotal',
      key: 'ventasVasoTotal',
    },
    {
      title: 'Vasos Ca. G',
      dataIndex: 'ventasVasoCalienteGrande',
      key: 'ventasVasoCalienteGrande',
    },
    {
      title: 'Vasos Ca. M',
      dataIndex: 'ventasVasoCalienteMediano',
      key: 'ventasVasoCalienteMediano',
    },
    {
      title: 'Vasos Fr. G',
      dataIndex: 'ventasVasoFrioGrande',
      key: 'ventasVasoFrioGrande',
    },
    {
      title: 'Vasos Fr. M',
      dataIndex: 'ventasVasoFrioMediano',
      key: 'ventasVasoFrioMediano',
    },
    {
      title: 'Ventas Recargas Total',
      dataIndex: 'totalVentasRecargas',
      key: 'totalVentasRecargas',
    },
    {
      title: 'Recargas Tarjeta',
      dataIndex: 'totalTarjetaRecargas',
      key: 'totalTarjetaRecargas',
    },
    {
      title: 'Recargas Efectivo',
      dataIndex: 'totalEfectivoRecargas',
      key: 'totalEfectivoRecargas',
    },
    {
      title: 'Tiempo Promedio',
      dataIndex: 'tiempoPromedio',
      key: 'tiempoPromedio',
    },
    {
      title: 'Tiempo Menor',
      dataIndex: 'tiempoMenor',
      key: 'tiempoMenor',
    },
    {
      title: 'Tiempo Mayor',
      dataIndex: 'tiempoMayor',
      key: 'tiempoMayor',
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
    },
  ];

  return (
    <>
      <PageHeader ghost title="Cortes" buttons={[<div key="1" className="page-header-actions" />]} />
      <Main>
        <Cards headless>
          {/* <Row gutter={15}>
            <Col xs={24}>
              <TopToolBox>
                <Row gutter={15} className="justify-content-left">
                  <Col xxl={14} lg={22} xs={24}>
                    <div className="table-toolbox-menu">
                      <span className="toolbox-menu-title"> Estatus:</span>
                      <Radio.Group onChange={handleChangeForFilter} defaultValue="">
                        <Radio.Button value="">Todos</Radio.Button>
                        {item.length &&
                          [...new Set(filterKey)].map(value => {
                            return (
                              <Radio.Button key={value} value={value}>
                                {value}
                              </Radio.Button>
                            );
                          })}
                      </Radio.Group>
                    </div>
                  </Col>
                </Row>
              </TopToolBox>
            </Col>
          </Row> */}
          <Row gutter={15}>
            <Col md={24}>
              <TableWrapper className="table-order table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{
                    current: pageNumber,
                    onChange: handleOnChangePage,
                    total,
                  }}
                />
              </TableWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

CortesTable.propTypes = {
  pageNumber: PropTypes.number,
  onChangePageNumber: PropTypes.func,
};
export default CortesTable;
