const actions = {
  GET_CLIENTS_BEGIN: 'GET_CLIENTS_BEGIN',
  GET_CLIENTS: 'GET_CLIENTS',
  GET_CLIENTS_ERR: 'GET_CLIENTS_ERR',
  CREATE_CLIENT: 'CREATE_CLIENT',
  CREATE_CLIENT_BEGIN: 'CREATE_CLIENT_BEGIN',
  CREATE_CLIENT_ERR: 'CREATE_CLIENT_ERR',

  getClients: data => {
    return {
      type: actions.GET_CLIENTS,
      data,
    };
  },

  getClientsBegin: () => {
    return {
      type: actions.GET_CLIENTS_BEGIN,
    };
  },

  getClientsError: err => {
    return {
      type: actions.GET_CLIENTS_ERR,
      err,
    };
  },
  createClients: () => {
    return {
      type: actions.CREATE_CLIENT,
    };
  },
  createClientsBegin: () => {
    return {
      type: actions.CREATE_CLIENT_BEGIN,
    };
  },

  createClientsError: err => {
    return {
      type: actions.CREATE_CLIENT_ERR,
      err,
    };
  },
};

export default actions;
