const actions = {
  GET_CORTES_BEGIN: 'GET_CORTES_BEGIN',
  GET_CORTES: 'GET_CORTES',
  GET_CORTES_ERR: 'GET_CORTES_ERR',

  getCortes: data => {
    return {
      type: actions.GET_CORTES,
      data,
    };
  },

  getCortesBegin: () => {
    return {
      type: actions.GET_CORTES_BEGIN,
    };
  },

  getCortesError: err => {
    return {
      type: actions.GET_CORTES_ERR,
      err,
    };
  },
};

export default actions;
