import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api/index';

const {
  getOrders,
  getOrdersError,
  getOrdersBegin,
  getCustomers,
  getCustomersBegin,
  getCustomersError,
  filterOrderBegin,
  filterOrderSuccess,
  filterOrderErr,
} = actions;

const fetchOrders = params => {
  return async dispatch => {
    try {
      const { history = false, page = 1, limit = 25 } = params || {};
      dispatch(getOrdersBegin());
      const pagination = `page=${page}&limit=${limit}`;
      const filter = history
        ? `?${pagination}`
        : `?statusOrder=Pendiente&statusOrder=Entregada&sortBy=createdAt:asc&${pagination}`;
      const response = await DataService.get(`${API.pos.orders}${history ? '/history' : ''}/${filter}`);
      return dispatch(getOrders(response.data));
    } catch (err) {
      return dispatch(getOrdersError(err?.message || 'Error al intentar obtener categorías'));
    }
  };
};

const fetchCustomers = () => {
  return async dispatch => {
    try {
      dispatch(getCustomersBegin());
      const response = await DataService.get(`${API.pos.customers}`);
      return dispatch(getCustomers(response.data.results));
    } catch (err) {
      return dispatch(getCustomersError(err?.message || 'Error al intentar obtener customers'));
    }
  };
};

const orderFilter = (column, value) => {
  return async (dispatch, getState) => {
    try {
      dispatch(filterOrderBegin());
      const orders = getState().orders.orders;
      if (!column) {
        dispatch(filterOrderSuccess([]));
      } else {
        const data = orders.filter(item => {
          if (value !== '') {
            return item[column] === value;
          }
          return item;
        });
        dispatch(filterOrderSuccess(data));
      }
    } catch (err) {
      dispatch(filterOrderErr(err));
    }
  };
};

export { fetchOrders, fetchCustomers, orderFilter };
