import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ClientsTable from '../../components/clientsTable/ClientsTable';
import { fetchClients } from '../../redux/clients/actionCreator';

const ClientsHistory = () => {
  const { loading } = useSelector(state => state.clients);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(fetchClients({ page: pageNumber }));
  }, [pageNumber, dispatch]);

  const handleOnChangePageNumber = number => {
    setPageNumber(number);
  };

  return (
    <>
      {loading ? (
        <Spin size="large" />
      ) : (
        <ClientsTable pageNumber={pageNumber} onChangePageNumber={handleOnChangePageNumber} />
      )}
    </>
  );
};

export default ClientsHistory;
