import axios from 'axios';
import { message } from 'antd';
import { getItem, removeItem } from '../../utility/localStorageControl';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const getToken = () => {
  const card = getItem('card');
  return card?.tokens?.access?.token;
};

const authHeader = () => {
  const token = getToken();
  return {
    Authorization: `Bearer ${token}`,
  };
};

const card = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json',
  },
});

class DataService {
  static get(path = '') {
    return card({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return card({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return card({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = '') {
    return card({
      method: 'DELETE',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return card({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
card.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, Authorization: `Bearer ${getToken()}` };

  return requestConfig;
});

card.interceptors.response.use(
  response => response,
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    if (response) {
      if (response.status !== 200) {
        message.error(response?.data?.message || 'Ha ocurrido un error');
        console.log('Error', response?.data?.message);

        if (response?.data?.code === 401 && response?.data?.message === 'Necesitas Autenticarte') {
          removeItem('card');
          window.location.reload();
          return false;
        }
        return Promise.reject(response.data);
      }
      return response.data;
    }
    message.error('Ha ocurrido un error de conexión');

    return Promise.reject(error);
  },
);
export { DataService };
