import actions from './actions';
const { GET_CORTES, GET_CORTES_ERR, GET_CORTES_BEGIN } = actions;
const initState = {
  cortes: [],
  page: 1,
  totalResults: 0,
  limit: 0,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const CortesReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CORTES:
      return {
        ...state,
        loading: false,
        cortes: data.results,
        totalResults: data.totalResults,
        limit: data.limit,
        page: data.page,
      };
    case GET_CORTES_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_CORTES_BEGIN:
      return {
        ...state,
        error: null,
        cortes: [],
        totalResults: initState.totalResults,
        limit: initState.limit,
        page: initState.page,
        loading: true,
      };

    default:
      return state;
  }
};
export default CortesReducer;
