const actions = {
  GET_USERS_BEGIN: 'GET_USERS_BEGIN',
  GET_USERS: 'GET_USERS',
  GET_USERS_ERR: 'GET_USERS_ERR',
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_BEGIN: 'CREATE_USER_BEGING',
  CREATE_USER_ERR: 'CREATE_USER_ERR',

  getUsers: data => {
    return {
      type: actions.GET_USERS,
      data,
    };
  },

  getUsersBegin: () => {
    return {
      type: actions.GET_USERS_BEGIN,
    };
  },

  getUsersError: err => {
    return {
      type: actions.GET_USERS_ERR,
      err,
    };
  },
  createUsers: () => {
    return {
      type: actions.CREATE_USER,
    };
  },
  createUsersBegin: () => {
    return {
      type: actions.CREATE_USER_BEGIN,
    };
  },

  createUsersError: err => {
    return {
      type: actions.CREATE_USER_ERR,
      err,
    };
  },
};

export default actions;
