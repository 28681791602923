import actions from './actions';

const {
  GET_CLIENTS,
  GET_CLIENTS_ERR,
  GET_CLIENTS_BEGIN,
  CREATE_CLIENT,
  CREATE_CLIENT_BEGIN,
  CREATE_CLIENT_ERR,
} = actions;
const initState = {
  clients: [],
  page: 1,
  totalResults: 0,
  limit: 0,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const ClientsReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CLIENTS:
      return {
        ...state,
        loading: false,
        clients: data.results,
        totalResults: data.totalResults,
        limit: data.limit,
        page: data.page,
      };
    case GET_CLIENTS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_CLIENTS_BEGIN:
      return {
        ...state,
        error: null,
        clients: [],
        totalResults: initState.totalResults,
        limit: initState.limit,
        page: initState.page,
        loading: true,
      };
    case CREATE_CLIENT:
      return {
        ...state,
        loadingCreate: false,
      };
    case CREATE_CLIENT_BEGIN:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_CLIENT_ERR:
      return {
        ...state,
        loadingCreate: false,
        error: err,
      };

    default:
      return state;
  }
};
export default ClientsReducer;
