const actions = {
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORIES_BEGIN: 'GET_CATEGORIES_BEGIN',
  GET_CATEGORIES_ERR: 'GET_CATEGORIES_ERR',
  GET_PRODUCTS_BEGIN: 'GET_PRODUCTS_BEGIN',
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCTS_ERR: 'GET_PRODUCTS_ERR',
  SEND_ORDER_BEGIN: 'SEND_ORDER_BEGIN',
  SEND_ORDER_SUCCEED: 'SEND_ORDER_SUCCEED',
  SEND_ORDER_ERR: 'SEND_ORDER_ERR',
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',

  getCategoriesBegin: () => {
    return {
      type: actions.GET_CATEGORIES_BEGIN,
    };
  },

  getCategories: data => {
    return {
      type: actions.GET_CATEGORIES,
      data,
    };
  },

  getCategoriesError: err => {
    return {
      type: actions.GET_CATEGORIES_ERR,
      err,
    };
  },

  getProducts: data => {
    return {
      type: actions.GET_PRODUCTS,
      data,
    };
  },

  getProductsBegin: () => {
    return {
      type: actions.GET_PRODUCTS_BEGIN,
    };
  },

  getProductsError: err => {
    return {
      type: actions.GET_PRODUCTS_ERR,
      err,
    };
  },

  sendOrderBegin: () => {
    return {
      type: actions.SEND_ORDER_BEGIN,
    };
  },

  sendOrderError: err => {
    return {
      type: actions.SEND_ORDER_ERR,
      err,
    };
  },

  sendOrderSucceed: data => {
    return {
      type: actions.SEND_ORDER_SUCCEED,
      data,
    };
  },

  setInitialState: () => {
    return {
      type: actions.SET_INITIAL_STATE,
    };
  },
};

export default actions;
