const actions = {
  GET_PRODUCTS_BEGIN: 'GET_PRODUCTS_BEGIN',
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCTS_ERR: 'GET_PRODUCTS_ERR',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  CREATE_PRODUCT_BEGIN: 'CREATE_PRODUCT_BEGIN',
  CREATE_PRODUCT_ERR: 'CREATE_PRODUCT_ERR',
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORIES_BEGIN: 'GET_CATEGORIES_BEGIN',
  GET_CATEGORIES_ERR: 'GET_CATEGORIES_ERR',
  GET_OPTIONS: 'GET_OPTIONS',
  GET_OPTIONS_BEGIN: 'GET_OPTIONS_BEGIN',
  GET_OPTIONS_ERR: 'GET_OPTIONS_ERR',

  getProducts: data => {
    return {
      type: actions.GET_PRODUCTS,
      data,
    };
  },

  getProductsBegin: () => {
    return {
      type: actions.GET_PRODUCTS_BEGIN,
    };
  },

  getProductsError: err => {
    return {
      type: actions.GET_PRODUCTS_ERR,
      err,
    };
  },

  createProducts: () => {
    return {
      type: actions.CREATE_PRODUCT,
    };
  },

  createProductsBegin: () => {
    return {
      type: actions.CREATE_PRODUCT_BEGIN,
    };
  },

  createProductsError: err => {
    return {
      type: actions.CREATE_PRODUCT_ERR,
      err,
    };
  },

  getCategoriesBegin: () => {
    return {
      type: actions.GET_CATEGORIES_BEGIN,
    };
  },

  getCategories: data => {
    return {
      type: actions.GET_CATEGORIES,
      data,
    };
  },

  getCategoriesError: err => {
    return {
      type: actions.GET_CATEGORIES_ERR,
      err,
    };
  },

  getOptionsBegin: () => {
    return {
      type: actions.GET_OPTIONS_BEGIN,
    };
  },

  getOptions: data => {
    return {
      type: actions.GET_OPTIONS,
      data,
    };
  },

  getOptionsError: err => {
    return {
      type: actions.GET_OPTIONS_ERR,
      err,
    };
  },
};

export default actions;
