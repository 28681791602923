import React from 'react';
// import Cookies from 'js-cookie';
import { Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthWrapper } from './style';
import { login } from '../../../../redux/authentication/actionCreator';
import Heading from '../../../../components/heading/heading';

const SignIn = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.auth.loading);
  const [form] = Form.useForm();

  const handleSubmit = data => {
    dispatch(login(data));
  };

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">
            Iniciar Sesión en <span className="color-grey">Admin</span>
          </Heading>
          <Form.Item
            name="email"
            rules={[{ message: 'Ingresa tu usuario o email!', required: true }]}
            placeholder="usuario/email"
            initialValue=""
            label="Usuario o Email"
          >
            <Input placeholder="Usuario" />
          </Form.Item>
          <Form.Item name="password" initialValue="" label="Password">
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button className="btn-signin" htmlType="submit" type="primary" size="large" disabled={isLoading}>
              {isLoading ? 'Cargando...' : 'Iniciar Sesión'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default SignIn;
