import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api/index';
import { message } from 'antd';

const { getUsers, getUsersError, getUsersBegin, createUsers, createUsersBegin, createUsersError } = actions;

const fetchUsers = params => {
  return async dispatch => {
    try {
      const { page = 1, limit = 10 } = params || {};
      dispatch(getUsersBegin());
      const pagination = `page=${page}&limit=${limit}`;
      const filter = `?sortBy=createdAt:asc&${pagination}`;
      const response = await DataService.get(`${API.pos.users}/${filter}`);
      return dispatch(getUsers(response.data));
    } catch (err) {
      return dispatch(getUsersError(err?.message || 'Error al intentar obtener usuarios'));
    }
  };
};

const createUser = params => {
  return async dispatch => {
    try {
      dispatch(createUsersBegin());
      await DataService.post(`${API.pos.users}/`, {
        email: params.email,
        password: params.password,
        name: params.name,
        role: params.role,
      });
      message.success('Usuario creado con éxito');
      dispatch(createUsers());
      dispatch(fetchUsers());
    } catch (err) {
      return dispatch(createUsersError(err?.message || 'Error al intentar crear el usuario'));
    }
  };
};
const changePassword = params => {
  return async dispatch => {
    try {
      dispatch(createUsersBegin());
      await DataService.patch(`${API.pos.users}/${params.idToUpdate}`, {
        password: params.password,
      });
      message.success('Contraseña cambiada con éxito');
      dispatch(createUsers());
      dispatch(fetchUsers());
    } catch (err) {
      return dispatch(createUsersError(err?.message || 'Error al intentar cambiar la contraseña'));
    }
  };
};

export { fetchUsers, createUser, changePassword };
