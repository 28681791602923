import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Radio, Row, Col, Switch, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';
import { OrderSummary } from './style';
import { Cards } from '../cards/frame/cards-frame';
import Heading from '../heading/heading';
import { fetchCardById } from '../../redux/cards/actionCreator';

const Ordersummary = ({
  clients = [],
  subtotal = 0,
  products = 0,
  amount = 0,
  monedero = false,
  paymentType = '',
  onChangeAmount,
  memberCardAmount = 0,
  onChangeDiscount,
  onChangePayWithMemberCard,
  onChangePaymentType,
  onChangecardQR,
}) => {
  const [form] = Form.useForm();
  const [state, setState] = useState({
    selectedRowKeys: [],
    showCam: false,
    qrData: '',
    name: '',
    cardNumber: '',
    coinpurse: 0,
    montoRecarga: 0,
    discount: 0,
    total: subtotal,
    discountMember: '',
    typepay: 0,
    cardChecked: false,
    payWithMemberCard: false,
  });

  const cardstate = useSelector(cardSate => cardSate.card);

  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.qrData) {
      dispatch(fetchCardById(state.qrData));
      setState({ ...state, name: '', cardNumber: '', coinpurse: 0, montoRecarga: 0 });
    }
    // eslint-disable-next-line
  }, [state.qrData]);

  useEffect(() => {
    if (cardstate?.card?.name) {
      const { id, name, cardname, coinpurse, montoRecarga = 0 } = cardstate.card;
      onChangecardQR(id, 0, cardname, coinpurse, montoRecarga);
      setState({ ...state, name, cardNumber: cardname, coinpurse, montoRecarga, showCam: false });
    } else if (!cardstate?.card) {
      onChangecardQR(0, null);
      setState({ ...state, showCam: true });
    }
    // eslint-disable-next-line
  }, [cardstate]);

  useEffect(() => {
    console.log('paywithcar', state.payWithMemberCard);
    // eslint-disable-next-line
  }, [state.payWithMemberCard]);

  useEffect(() => {
    setState({ ...state, name: '', cardNumber: '', coinpurse: 0, montoRecarga: 0, qrData: '', showCam: false });
    // eslint-disable-next-line no-use-before-define
    handleTypePay('Efectivo');
    // eslint-disable-next-line
  }, [monedero]);

  const onChangeRadio = evt => {
    const value = evt?.target?.value;
    onChangePaymentType(value);
  };
  const handleOnChangeAmount = evt => {
    const _amount = evt?.target?.value;
    onChangeAmount(_amount);
  };
  const handleScan = data => {
    if (data) {
      setState({ ...state, qrData: data, showCam: false });
    }
  };

  const handleError = err => {
    setState({ ...state, err });
  };

  const handleTypePay = type => {
    setState({ ...state, typepay: type });

    console.log('type', type);
    console.log('is', type === 'Monedero');

    let amount = 0;
    if (type === 'Monedero') {
      const { id, cardname, coinpurse = 0, montoRecarga = 0 } = cardstate.card;
      if (coinpurse + montoRecarga >= state.total) amount = state.total;
      setTimeout(() => {
        onChangecardQR(id, amount, cardname, coinpurse, montoRecarga);
      }, 50);
    }
  };

  const handleCheckCard = checked => {
    console.log('checked', checked);

    if (checked) setState({ ...state, showCam: true, qrData: '', cardChecked: true, payWithMemberCard: false });
    else
      setState({
        ...state,
        showCam: false,
        name: '',
        cardNumber: '',
        coinpurse: 0,
        montoRecarga: 0,
        cardChecked: false,
        payWithMemberCard: false,
      });
    onChangecardQR(null, null);
    setTimeout(() => {
      onChangePayWithMemberCard(0);
    }, 50);
  };

  const handleOnPayWithMemberCard = evt => {
    const { checked } = evt.target;
    console.log('checked', checked);
    let _memberCardAmount = 0;
    if (checked) {
      _memberCardAmount = state.coinpurse + state.montoRecarga >= subtotal ? subtotal : state.coinpurse + state.montoRecarga;
    }
    setState({ ...state, payWithMemberCard: checked });
    onChangePayWithMemberCard(_memberCardAmount);
  };
  return (
    <Cards
      bodyStyle={{
        backgroundColor: '#F8F9FB',
        borderRadius: '20px',
      }}
      headless
    >
      <OrderSummary>
        <Heading className="summary-table-title" as="h4">
          Resumen de la Orden
        </Heading>

        <div className="order-summary-inner">
          <ul className="summary-list">
            <li>
              <span className="summary-list-title">Subtotal :</span>
              <span className="summary-list-text">{`$${subtotal}`}</span>
            </li>
            <li>
              <span className="summary-list-title">Productos :</span>
              <span className="summary-list-text">{`${products}`}</span>
            </li>
          </ul>
          <Form form={form} name="promo">
            <Form.Item name="paymentType" label="Tarjeta Latido">
              <div>
                <Switch onChange={handleCheckCard} defaultChecked={state.cardChecked} />
                {state.showCam && state.cardChecked ? (
                  <QrReader
                    showViewFinder={state.showCam}
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '300px', marginBottom: '10px' }}
                  />
                ) : (
                  <span />
                )}
              </div>
            </Form.Item>
            {state.cardChecked && (
              <div>
                <Row>
                  <Col span={12}>
                    <Form.Item name="nombre" label="Nombre" style={{ marginRight: '5px' }}>
                      <div>
                        <Input size="middle" type="text" placeholder="Nombre" value={state.name} />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="coinpurse" label="Dinero Elec.">
                      <div>
                        <Input size="middle" type="number" placeholder="Cantidad" value={state.coinpurse + state.montoRecarga} />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="payWithCard">
                      <div>
                        <Checkbox
                          disabled={state.coinpurse + state.montoRecarga === 0}
                          style={{ fontSize: '15px' }}
                          onChange={handleOnPayWithMemberCard}
                        >
                          Usar Dinero Electrónico
                        </Checkbox>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
            <Form.Item name="paymentType" label="Tipo de Pago">
              <div>
                <Radio.Group DefaultValue="Efectivo" value={state.typepay} onChange={onChangeRadio} size="small">
                  <Radio.Button size key="Efectivo" value="Efectivo" onClick={() => handleTypePay('Efectivo')}>
                    Efectivo
                  </Radio.Button>
                  <Radio.Button key="Tarjeta" value="Tarjeta" onClick={() => handleTypePay('Tarjeta')}>
                    Tarjeta
                  </Radio.Button>
                </Radio.Group>
              </div>
            </Form.Item>

            <Form.Item name="cantidad" label="Monto de Pago">
              <div>
                <Input
                  size="middle"
                  type="number"
                  placeholder="Cantidad"
                  onChange={handleOnChangeAmount}
                  value={amount}
                />
              </div>
            </Form.Item>
          </Form>
          <br />
          <Heading className="summary-total" as="h5">
            <span className="summary-total-label">Subtotal : </span>
            <span className="summary-total-amount">{`$${subtotal}`}</span>
          </Heading>
          {state.payWithMemberCard && (
            <Heading className="summary-total" as="h5">
              <span className="summary-total-label">Descuento Tarjeta Latido: </span>
              <span className="summary-total-amount-discount">{`- $${memberCardAmount}`}</span>
            </Heading>
          )}
          <Heading className="summary-total" as="h5">
            <span className="summary-total-label">Total : </span>
            <span className="summary-total-amount">{`$${state.total - memberCardAmount}`}</span>
          </Heading>
          <div className="summary-total" as="h5">
            <span className="summary-total-label">Cambio : </span>
            <span className="summary-total-amount-change">{`$${amount - (state.total - memberCardAmount)}`}</span>
          </div>
        </div>
      </OrderSummary>
    </Cards>
  );
};
Ordersummary.propTypes = {
  products: PropTypes.number,
  subtotal: PropTypes.number,
  clients: PropTypes.array,
  cards: PropTypes.array,
  total: PropTypes.number,
  discount: PropTypes.number,
  paymentType: PropTypes.string,
  memberCardAmount: PropTypes.string,
  onChangeAmount: PropTypes.func,
  onChangeDiscount: PropTypes.func,
  onChangePayWithMemberCard: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangecardQR: PropTypes.func,
};

export default Ordersummary;
