import actions from './actions';
const {
  GET_RECHARGES,
  GET_RECHARGES_ERR,
  GET_RECHARGES_BEGIN,
  GET_CUSTOMERS,
  GET_CUSTOMERS_BEGIN,
  GET_CUSTOMERS_ERR,
  FILTER_RECHARGE_BEGIN,
  FILTER_RECHARGE_ERR,
  FILTER_RECHARGE_SUCCESS,
} = actions;
const initState = {
  recharges: [],
  page: 1,
  totalResults: 0,
  limit: 0,
  customers: [],
  loading: false,
  error: null,
  filterOrders: [],
};

/**
 *
 * @todo impure state mutation/explaination
 */
const RechargesReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_RECHARGES:
      return {
        ...state,
        loading: false,
        recharges: data.results,
        totalResults: data.totalResults,
        limit: data.limit,
        page: data.page,
      };
    case GET_RECHARGES_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_RECHARGES_BEGIN:
      return {
        ...state,
        error: null,
        recharges: [],
        totalResults: initState.totalResults,
        limit: initState.limit,
        page: initState.page,
        loading: true,
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        loading: false,
        customers: data,
      };
    case GET_CUSTOMERS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_CUSTOMERS_BEGIN:
      return {
        ...state,
        error: null,
        customers: [],
        loading: true,
      };
    case FILTER_RECHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
        filterOrders: data,
      };
    case FILTER_RECHARGE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case FILTER_RECHARGE_BEGIN:
      return {
        ...state,
        error: null,
        filterOrders: [],
        loading: true,
      };
    default:
      return state;
  }
};
export default RechargesReducer;
