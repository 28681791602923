import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import CortesTable from '../../components/cortesTable/CortesTable';
import { fetchCortes } from '../../redux/cortes/actionCreator';

const CortesHistory = () => {
  const { loading } = useSelector(state => state.orders);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(fetchCortes({ page: pageNumber }));
  }, [pageNumber, dispatch]);

  const handleOnChangePageNumber = number => {
    setPageNumber(number);
  };

  return (
    <>
      {loading ? (
        <Spin size="large" />
      ) : (
        <CortesTable pageNumber={pageNumber} onChangePageNumber={handleOnChangePageNumber} />
      )}
    </>
  );
};

export default CortesHistory;
