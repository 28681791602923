import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Modal, Table, Popconfirm, Form, Input, Select, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../page-headers/page-headers';
import { Main, TableWrapper, BasicFormWrapper, AddUser } from '../../container/styled';
import { Button } from '../buttons/buttons';
import { Cards } from '../cards/frame/cards-frame';
import { createUser, changePassword } from '../../redux/users/actionCreator';
const { Option } = Select;
const UsersTable = ({ pageNumber, onChangePageNumber, onDeleteUser }) => {
  const { filterUsers, users, total, loadingCreate } = useSelector(state => {
    return {
      filterUsers: state.users.filterUsers,
      users: state.users.users,
      loadingCreate: state.users.loadingCreate,
      total: state.users.totalResults,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [state, setState] = useState({
    notData: filterUsers,
    selectedRowKeys: [],
    update: {},
    modalVisible: false,
    modalPasswordVisible: false,
    idToUpdate: '',
  });

  const handleOnChangePage = params => {
    onChangePageNumber(params);
  };

  const handleOnDeleteUser = userId => {
    onDeleteUser(userId);
  };

  const handleCancel = () => {
    setState({ ...state, modalVisible: false, modalPasswordVisible: false });
  };

  const handleOk = data => {
    dispatch(createUser(data));
  };
  const handleOkChangePassword = data => {
    dispatch(changePassword({ ...data, idToUpdate: state.idToUpdate }));
  };

  const toggleModal = () => {
    setState({ ...state, modalVisible: !state.modalVisible });
  };

  const handleOnShowPasswordChange = idToUpdate => {
    setState({ ...state, modalPasswordVisible: !state.modalPasswordVisible, idToUpdate });
  };
  const closeModalPassword = () => {
    setState({ ...state, modalPasswordVisible: !state.modalPasswordVisible, idToUpdate: '' });
  };

  const dataSource = [];
  if (users.length) {
    let iteareProp = users;
    if (filterUsers?.length > 0) iteareProp = filterUsers;
    iteareProp.map((value, key) => {
      const { role, id, email, name } = value;

      return dataSource.push({
        key: key + 1,
        id: <span className="user-id">{id.substring(8)}</span>,
        role: <span className="customer-name">{role}</span>,
        email: <span className="customer-name">{email}</span>,
        name: <span className="customer-name">{name}</span>,
        action: (
          <div className="table-actions">
            <>
              <Button className="btn-icon" type="warning" onClick={() => handleOnShowPasswordChange(id)} shape="circle">
                <FeatherIcon icon="lock" size={16} />
              </Button>
              <Popconfirm
                title="¿Seguro que quieres borrar este usuario?"
                onConfirm={() => {
                  handleOnDeleteUser(id);
                }}
                okText="Si"
                cancelText="No"
              >
                <Button className="btn-icon" type="danger" shape="circle">
                  <FeatherIcon icon="trash-2" size={16} />
                </Button>
              </Popconfirm>
            </>
          </div>
        ),
      });
    });
  }

  const columns = [
    {
      title: 'Id Usuario',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Tipo Usuario',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Acción',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  return (
    <>
      <PageHeader
        ghost
        title="Users"
        buttons={[
          <div key="1" className="page-header-actions">
            <Button className="btn-add_new" size="default" key="1" type="primary" onClick={toggleModal}>
              <FeatherIcon icon="user-plus" size={14} /> Agregar Usuario
            </Button>
          </div>,
        ]}
      />
      <Main>
        <Modal title="Informacion del usuario" visible={state.modalVisible} footer={null} onCancel={handleCancel}>
          <div className="project-modal">
            <BasicFormWrapper>
              <AddUser>
                <Form form={form} name="user" onFinish={handleOk}>
                  <Form.Item label="Nombre" name="name" rules={[{ required: true, message: 'Ingresa un nombre' }]}>
                    <Input placeholder="Nombre del usuario" />
                  </Form.Item>

                  <Form.Item
                    label="Email Valido"
                    name="email"
                    rules={[{ required: true, message: 'Ingresa un email valido', type: 'email' }]}
                  >
                    <Input placeholder="nombre@ejemplo.com" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Contrasña"
                    rules={[
                      {
                        required: true,
                        min: 8,
                        message: 'Ingresa una contraseña mayor a 8 caracteres',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirmar Contraseña"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Confirma la contraseña',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Las dos contraseñas deben ser iguales');
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="role"
                    label="Tipo Usuario"
                    rules={[{ required: true, message: 'Selecciona un tipo de usuario' }]}
                  >
                    <Select placeholder="Selecciona un tipo de usuario">
                      <Option value="cajero">Cajero</Option>
                      <Option value="tablet">Tablet</Option>
                      <Option value="pantalla">Pantalla</Option>
                      <Option value="admin">Administrador</Option>
                      <Option value="drive-thru">Drive Thru</Option>
                    </Select>
                  </Form.Item>
                  {loadingCreate ? (
                    <Spin></Spin>
                  ) : (
                    <Button htmlType="submit" size="default" type="primary" key="submit">
                      Agregar Nuevo Usuario
                    </Button>
                  )}
                </Form>
              </AddUser>
            </BasicFormWrapper>
          </div>
        </Modal>

        <Modal
          title="Cambio de contraseña"
          visible={state.modalPasswordVisible}
          footer={null}
          onCancel={closeModalPassword}
        >
          <div className="project-modal">
            <BasicFormWrapper>
              <AddUser>
                <Form form={form} name="changepassword" onFinish={handleOkChangePassword}>
                  <Form.Item
                    name="password"
                    label="Contrasña"
                    rules={[
                      {
                        required: true,
                        min: 8,
                        message: 'Ingresa una contraseña mayor a 8 caracteres',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirmar Contraseña"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Confirma la contraseña',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Las dos contraseñas deben ser iguales');
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  {loadingCreate ? (
                    <Spin></Spin>
                  ) : (
                    <Button htmlType="submit" size="default" type="primary" key="submit">
                      Cambiar la contraseña
                    </Button>
                  )}
                </Form>
              </AddUser>
            </BasicFormWrapper>
          </div>
        </Modal>
        <Cards headless>
          <Row gutter={15}>
            <Col md={24}>
              <TableWrapper className="table-user table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{
                    current: pageNumber,
                    onChange: handleOnChangePage,
                    total,
                  }}
                />
              </TableWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

UsersTable.propTypes = {
  pageNumber: PropTypes.number,
  onChangePageNumber: PropTypes.func,
  onDeleteUser: PropTypes.func,
};
export default UsersTable;
