/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { get, isEmpty, filter, includes, find, findIndex } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, message, Spin, Radio, Badge, Result, Input } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
// import { Checkbox } from '../../components/checkbox/checkbox';
import Palette from '../../components/color-palette/palette';
import { Main, ButtonLineWrapper, ButtonsWrapper } from '../styled';
import { fetchCategories, fetchProducts, sendOrder, initializeState } from '../../redux/pos/actionCreator';
import { theme } from '../../config/theme/themeVariables';
import CartList from '../../components/cartList/cartList';
import OrderCard from '../../components/orderCard/orderCard';
import { fetchPrinterConfig } from '../../utility/utility';
const { TextArea } = Input;

const STEPS = {
  SELECT_CATEGORY: 1,
  SELECT_PRODUCT: 2,
  SELECT_OPTIONS: 3,
  ADD_MORE_STEP: 4,
  ORDER_FINISHED: 5,
};

const initialState = {
  categorySelected: null,
  productSelected: null,
  step: 1,
  title: 'Selecciona la categoría',
  showModalPay: false,
  orderCreated: {},
  loadingPay: false,
};

const initialOrder = { products: [], customerName: '', note: '' };
const POS = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { categories, products, loading, createOrderResult = {} } = useSelector(state => state.pos);
  const [state, setState] = useState(initialState);
  const [productOptions, setProductOptions] = useState({});
  const [productOptionsCount, setProductOptionsCount] = useState({});
  const [productToAdd, setProductToAdd] = useState({});
  const [order, setOrder] = useState(initialOrder);
  const { categorySelected, step, title, productSelected, orderCreated } = state;


  const [ipName, setIpName] = useState('')

  const fetchPrinterConfiguration = async () => {
    const ipName = await fetchPrinterConfig()
    setIpName(ipName)
  };

  useEffect(() => {
    console.log('useEffect ipConfig')
    if(!ipName){
      fetchPrinterConfiguration()
    }
  }, [ipName])


  const handleOnClickCategory = data => {
    setState({ ...state, step: STEPS.SELECT_PRODUCT, title: 'Selecciona un producto', categorySelected: data });
  };

  const setInitialStateScreen = () => {
    setState(initialState);
    setOrder(initialOrder);
    setProductToAdd({});
    dispatch(initializeState());
  };
  const handleOnClickNewOrder = () => {
    setInitialStateScreen();
  };

  const handleOnClickGoToOrders = () => {
    setInitialStateScreen();
    history.replace('/admin/ordenes');
  };

  const handleOnClickProduct = data => {
    const optionsToSelect = data?.options?.length;
    setState({
      ...state,
      step: optionsToSelect ? STEPS.SELECT_OPTIONS : STEPS.ADD_MORE_STEP,
      title: optionsToSelect ? `Selecciona opciones del producto: ${data.name}` : 'Resumen de la orden',
      productSelected: data,
    });

    setProductToAdd({ ...productToAdd, ...data, finalPrice: data.price });
    if (!optionsToSelect) {
      setOrder({ ...order, products: [...order.products, { ...data, finalPrice: data.price }] });
    }
  };

  const onNoteChange = evt => {
    const note = evt?.target?.value;
    setOrder({
      ...order,
      note,
    });
  };

  const onNameChange = evt => {
    const customerName = evt?.target?.value;
    setOrder({
      ...order,
      customerName,
    });
  };

  const incrementUpdate = (index, quantity) => {
    const data = parseInt(quantity, 10) + 1;
    const allItems = order.products;
    allItems[index].quantity = data;
    setOrder({ ...order, products: allItems });
  };

  const decrementUpdate = (index, quantity) => {
    const data = parseInt(quantity, 10) >= 2 ? parseInt(quantity, 10) - 1 : 1;
    const allItems = order.products;
    allItems[index].quantity = data;
    setOrder({ ...order, products: allItems });
  };

  // USE EFFECTS
  useEffect(() => {
    if (!isEmpty(createOrderResult)) {
      if (!createOrderResult.error) message.success('Orden Creada Exitosamente');

      setState({
        ...state,
        ...initialState,
        step: STEPS.ORDER_FINISHED,
        title: 'Estado de la Orden',
        orderCreated: createOrderResult.orderCreated,
      });
    }
    // eslint-disable-next-line
  }, [createOrderResult]);

  useEffect(() => {
    const priceProduct = productToAdd.price;
    const viewOptions = [];
    const counterOptions = [];
    const overPrice = get(productToAdd, 'options', []).reduce((addition, option) => {
      switch (option.type) {
        case 'BOOLEAN':
          const optionsSelections = productOptions[option.id] && productOptions[option.id].optionSelected;
          if (!isEmpty(optionsSelections)) {
            const optionsSelected = filter(option?.options || [], optionSelect => {
              if (includes(optionsSelections, optionSelect._id)) {
                return true;
              }
              return false;
            });
            if (!isEmpty(optionsSelected)) {
              const objectOptionView = { name: option.name, optionsSelected: [] };
              // eslint-disable-next-line no-param-reassign
              addition += optionsSelected.reduce((additionToAdd, selectionToAdd) => {
                objectOptionView.optionsSelected.push(selectionToAdd.title);
                return additionToAdd + selectionToAdd.extraPrice;
              }, 0);
              viewOptions.push(objectOptionView);
            }
          }
          break;
        case 'COUNTER':
          const optionsCounters = get(productOptionsCount, `[${option.id}].optionCounterQuantity`, []);

          if (!isEmpty(optionsCounters)) {
            const optionsCountActive = filter(optionsCounters, optionCount => {
              return optionCount.quantity > 0;
            });
            if (!isEmpty(optionsCountActive)) {
              optionsCountActive.forEach(option => {
                counterOptions.push({ title: option.title, optionCounterQuantity: option.quantity });
                // eslint-disable-next-line no-param-reassign
                addition += option.quantity * option.quantityExtraPrice;
              });
            }
          }
          break;
        default:
          break;
      }

      return addition;
    }, 0);
    setProductToAdd({ ...productToAdd, finalPrice: overPrice + priceProduct, viewOptions, counterOptions });
    // eslint-disable-next-line
  }, [productOptions, productOptionsCount]);

  const handleOnSelectOption = (id, evt) => {
    const selectedValue = evt?.target?.value;
    const currentOption = productOptions[id] || {};
    currentOption.optionSelected = [selectedValue];

    setProductOptions({ ...productOptions, [id]: currentOption });
  };

  const handleOnClickCalculateCount = (optionId, countOptionId, operation = '') => {
    const optionCount = get(productOptionsCount, `[${optionId}]`, {});
    if (isEmpty(optionCount)) {
      return;
    }
    const index = findIndex(optionCount.optionCounterQuantity || [], { _id: countOptionId });

    if (index === -1) return;
    const currentQuantity = optionCount.optionCounterQuantity[index].quantity;
    switch (operation) {
      case '+':
        optionCount.optionCounterQuantity[index].quantity = currentQuantity + 1;
        break;
      case '-':
        if (currentQuantity > 0) optionCount.optionCounterQuantity[index].quantity = currentQuantity - 1;
        else return;
        break;

      default:
        return;
    }

    setProductOptionsCount({
      ...productOptionsCount,
      [optionId]: { ...optionCount },
    });
  };

  const handleOnClickOptionFinished = () => {
    setState({ ...state, step: STEPS.ADD_MORE_STEP, title: 'Agregar mas productos o termina la orden' });
    setOrder({ ...order, products: [...order.products, { ...productToAdd, productOptions, productOptionsCount }] });
  };

  const handleOnClickOrderFinished = () => {
    // TODO CREATE ORDER
    if (!order.customerName) message.warning('Debes capturar el nombre de la orden');
    else dispatch(sendOrder(order));
    // setState({ ...state, step: STEPS.SELECT_CATEGORY, title: 'Selecciona la categoría' });
  };
  const handleOnClickAddMore = () => {
    setState({ ...state, step: STEPS.SELECT_CATEGORY, title: 'Selecciona la categoría' });
    setProductToAdd({});
  };

  const removeItemFromOrder = idToRemove => {
    const notDeletedProducts = order.products.filter(element => element.id !== idToRemove);
    setOrder({ ...order, products: notDeletedProducts });
  };

  const onOptionRadioChange = (idOption, idOptionSelected, extraPrice = 0) => {
    const optionSelected = idOptionSelected?.target?.value;
    const option = productOptions[idOption];
    setProductOptions({
      ...productOptions,
      [idOption]: { ...option, optionSelected: [optionSelected] },
    });
  };

  // const logData = useCallback(() => {
  // }, [data]);

  const initializeProductOptions = _productSelected => {
    if (_productSelected?.options?.length > 0) {
      const options = _productSelected?.options || [];
      const optionMap = options.reduce(
        (currentHashes, option) => {
          let { optionsHash, optionsCountHash } = currentHashes;

          const initialSelected = option.mandatory && option.options[0]._id;

          switch (option.type) {
            case 'BOOLEAN':
              // eslint-disable-next-line no-param-reassign
              optionsHash[option.id] = {
                optionId: option.id,
                mandatory: option.mandatory,
                optionSelected: initialSelected ? [initialSelected] : [],
                selectedExtraPrice: initialSelected ? option.options[0].extraPrice : 0,
              };
              break;
            case 'COUNTER':
              const counterMap = get(option, 'options', []).map(optionCount => {
                return {
                  title: optionCount.title,
                  quantityExtraPrice: optionCount.extraPrice,
                  quantity: 0,
                  _id: optionCount._id,
                };
              });

              optionsCountHash[option.id] = {
                optionId: option.id,
                mandatory: option.mandatory,
                optionCounterQuantity: counterMap,
                optionCounterExtraPrice: 0,
              };
              break;
            default:
              break;
          }

          return { optionsHash, optionsCountHash };
        },
        { optionsHash: {}, optionsCountHash: {} },
      );
      setProductOptions(optionMap.optionsHash);
      setProductOptionsCount(optionMap.optionsCountHash);
    }
  };

  // USE EFFECTS
  // useEffect(() => {
  //   //if (error) message.error(error);
  // }, [error]);

  // eslint-disable-next-line
  useEffect(() => {
    switch (step) {
      case STEPS.SELECT_CATEGORY:
        dispatch(fetchCategories());
        break;
      case STEPS.SELECT_PRODUCT:
        // eslint-disable-next-line
        dispatch(fetchProducts(categorySelected.id));
        break;
      case STEPS.SELECT_OPTIONS:
        initializeProductOptions(productSelected);
        break;
      // case STEPS.ADD_MORE_STEP:
      //   initializeProductOptions(productSelected);
      // break;
      default:
        break;
    }
  }, [step, dispatch, categorySelected, productSelected]);
  // END USE EFFECTS

  

  const renderOptionsProduct = () => {
    const { options } = productSelected;
    return options.map(option => {
      const selectOptions = option.options || [];
      const renderOptionsSelect = selectOptions.map(selection => {
        const { extraPrice, title: titleSelection, _id: id } = selection;
        return (
          // In case it has an extra price it will calculate +1 to show the + symbol next the de badge
          <Badge key={id} count={extraPrice ? extraPrice + 1 : 0} overflowCount={extraPrice || 1}>
            <Radio.Button
              key={id}
              value={id}
              onChange={selectedItem => onOptionRadioChange(option.id, selectedItem, extraPrice)}
            >
              {titleSelection}
            </Radio.Button>
          </Badge>
        );
      });

      const renderOptionsCounter = selectOptions.map((selection, idx) => {
        const optionCounter = get(productOptionsCount, `[${selection.optionId}].optionCounterQuantity`, []);

        const optionSelected = find(optionCounter, { _id: selection._id });
        const quantity = get(optionSelected, 'quantity', 0);
        return (
          <div key={`${selection.optionId}${idx}`}>
            <span>{selection.title}</span>
            <Button
              onClick={() => handleOnClickCalculateCount(selection.optionId, selection._id, '-')}
              className="btn-dec"
              type="default"
            >
              <FeatherIcon icon="minus" size={12} />
            </Button>
            {quantity}
            <Button
              onClick={() => handleOnClickCalculateCount(selection.optionId, selection._id, '+')}
              className="btn-inc"
              type="default"
            >
              <FeatherIcon icon="plus" size={12} />
            </Button>
          </div>
        );
      });

      switch (option.type) {
        case 'BOOLEAN':
        case 'OPTIONS': {
          // eslint-disable-next-line no-underscore-dangle
          const idSelect = selectOptions[0]._id;
          return (
            <Col sm={8} xs={24} key={option.id}>
              <Cards title={option.name}>
                <Radio.Group
                  defaultValue={option.mandatory ? idSelect : null}
                  onChange={evt => handleOnSelectOption(option.id, evt)}
                  size="large"
                >
                  {renderOptionsSelect}
                </Radio.Group>
              </Cards>
            </Col>
          );
        }
        case 'COUNTER':
          return (
            <Col sm={8} xs={24} key={option.id}>
              <Cards title={option.name}>{renderOptionsCounter}</Cards>
            </Col>
          );
        default:
          return null;
      }
    });
  };

  const renderStep = () => {
    switch (step) {
      // PRODUCT SELECTION
      case STEPS.SELECT_PRODUCT:
        return (
          <Row gutter={16}>
            {products.map((product, index) => (
              <Col sm={8} xs={24} key={index}>
                <Palette
                  gradient
                  direction="90deg"
                  colorCode={[theme['gray-solid'], theme['light-gray-color']]}
                  onClick={() => handleOnClickProduct(product)}
                >
                  {product.name}
                </Palette>
              </Col>
            ))}
          </Row>
        );
      // PRODUCT SELECTION
      case STEPS.SELECT_OPTIONS:
        return (
          <>
            <Row gutter={16}>{renderOptionsProduct()}</Row>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button size="large" type="primary" onClick={handleOnClickOptionFinished}>
                Continuar
              </Button>
            </div>
          </>
        );

      case STEPS.ADD_MORE_STEP:
        return (
          <Cards title="Productos Seleccionados" size="large">
            <CartList
              products={order.products}
              onRemoveItem={removeItemFromOrder}
              incrementUpdate={incrementUpdate}
              decrementUpdate={decrementUpdate}
            />
            <Row
              gutter={16}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginBottom: '25px' }}
            >
              <Col sm={8} xs={24}>
                <span>Nombre de la orden</span>
                <Input onChange={onNameChange} placeholder="Nombre del cliente" value={order.customerName} />
              </Col>
              <Col sm={8} xs={24}>
                <span>Agregar Nota (Opcional)</span>
                <TextArea
                  onChange={onNoteChange}
                  autoSize
                  placeholder="Agrega alguna nota a la orden"
                  value={order.note}
                />
              </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: '15px' }}>
              <Button size="large" type="primary" onClick={handleOnClickAddMore}>
                <FeatherIcon icon="plus" />
                Agregar Más
              </Button>
              <Button size="large" type="success" onClick={handleOnClickOrderFinished}>
                <FeatherIcon icon="send" />
                Terminar Orden
              </Button>
            </div>
          </Cards>
        );
      case STEPS.ORDER_FINISHED:
        return (
          <Cards
            bodyStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            title="Orden Finalizada"
            size="large"
          >
            {createOrderResult?.error ? (
              <Result
                status={'error'}
                title="Resultado de la orden"
                subTitle={createOrderResult.error}
                style={{ padding: 0 }}
                extra={[]}
              />
            ) : (
              <>
                <div style={{ maxWidth: '400px', border: '2px', borderBlockColor: theme['gray-color'] }}>
                  <OrderCard
                    clients={[]}
                    ipName={ipName}
                    order={orderCreated}
                    onSuccessChangeOrder={() => console.log('changed')}
                    showActions={true}
                  />
                </div>
              </>
            )}
            <ButtonsWrapper>
              <ButtonLineWrapper>
                <Button
                  className="btn-add_new"
                  size="default"
                  type="primary"
                  key="orders"
                  onClick={handleOnClickGoToOrders}
                >
                  <FeatherIcon icon="shopping-bag" size={14} />
                  Lista de Órdenes
                </Button>
              </ButtonLineWrapper>
              <ButtonLineWrapper>
                <Button
                  className="btn-add_new"
                  size="default"
                  type="success"
                  key="shop"
                  onClick={handleOnClickNewOrder}
                >
                  <FeatherIcon icon="shopping-cart" size={14} />
                  Crear Otra Orden
                </Button>
              </ButtonLineWrapper>
            </ButtonsWrapper>
          </Cards>
        );
      // STEPS.SELECT_CATEGORY
      default:
        return (
          <Row gutter={16}>
            {categories.map((category, index) => (
              <Col sm={8} xs={24} key={index}>
                <Palette
                  gradient
                  direction="90deg"
                  colorCode={[theme['secondary-color'], theme['primary-color']]}
                  onClick={() => handleOnClickCategory(category)}
                >
                  {category.name}
                </Palette>
              </Col>
            ))}
          </Row>
        );
    }
  };

  return (
    <>
      <PageHeader ghost title="Inicio" />
      <Main>
        <Cards title={title} size="large">
          <div className="columnCardsWrapper">{loading ? <Spin size="large" /> : renderStep()}</div>
        </Cards>
      </Main>
    </>
  );
};

export default POS;
