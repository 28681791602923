import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Dashboard = lazy(() => import('../../container/dashboard'));
const POS = lazy(() => import('../../container/pos'));
const Orders = lazy(() => import('../../container/orders'));
const OrdersHistory = lazy(() => import('../../container/ordersHistory'));
const RechargesHistory = lazy(() => import('../../container/rechargesHistory'));
const CortesHistory = lazy(() => import('../../container/cortesHistory'));
const Card = lazy(() => import('../../container/card'));
const Caja = lazy(() => import('../../container/caja'));
const ClientsHistory = lazy(() => import('../../container/clientsHistory'));
const Users = lazy(() => import('../../container/users'));
const Products = lazy(() => import('../../container/products'));
const Configuration = lazy(() => import('../../container/configuration'));

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Dashboard} />
      <Route exact path={`${path}/pos`} component={POS} />
      <Route exact path={`${path}/ordenes`} component={Orders} />
      <Route exact path={`${path}/ventas`} component={OrdersHistory} />
      <Route exact path={`${path}/caja`} component={Caja} />
      <Route exact path={`${path}/usuarios`} component={Users} />
      <Route exact path={`${path}/cortes`} component={CortesHistory} />
      <Route exact path={`${path}/clients`} component={ClientsHistory} />
      <Route exact path={`${path}/products`} component={Products} />
      <Route exact path={`${path}/cards`} component={Card} />
      <Route exact path={`${path}/recargas`} component={RechargesHistory} />
      <Route exact path={`${path}/configuracion`} component={Configuration} />
    </Switch>
  );
};

export default DashboardRoutes;
