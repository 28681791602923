import React, { useEffect, useState } from 'react';
import { message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import RechargeTable from '../../components/rechargesTable/RechargesTable';
import { fetchRecharges } from '../../redux/recharges/actionCreator';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api';

const RechargesHistory = () => {
  const { loading } = useSelector(state => state.recharges);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [state, setState] = useState({ loadingScreen: false });

  useEffect(() => {
    dispatch(fetchRecharges({ history: true, page: pageNumber }));
  }, [pageNumber, dispatch]);

  const handleOnChangePageNumber = number => {
    setPageNumber(number);
  };

  const handleOnDeleteOrder = async idToRemove => {
    setState({
      ...state,
      loading: true,
    });

    DataService.delete(`${API.pos.recargas}/${idToRemove}`)
      .then(_data => {
        message.success('Orden Eliminada');
        setState({ ...state, loading: false });
        dispatch(fetchRecharges({ history: true, page: pageNumber }));
      })
      .catch(_err => {
        setState({ ...state, loading: false });
      });
  };

  return (
    <>
      {loading ? (
        <Spin size="large" />
      ) : (
        <RechargeTable
          pageNumber={pageNumber}
          onChangePageNumber={handleOnChangePageNumber}
          onDeleteOrder={handleOnDeleteOrder}
        />
      )}
    </>
  );
};

export default RechargesHistory;
