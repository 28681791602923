import actions from './actions';

const {
  GET_CATEGORIES,
  GET_CATEGORIES_ERR,
  GET_PRODUCTS,
  GET_PRODUCTS_ERR,
  GET_PRODUCTS_BEGIN,
  GET_CATEGORIES_BEGIN,
  SEND_ORDER_ERR,
  SEND_ORDER_BEGIN,
  SEND_ORDER_SUCCEED,
  SET_INITIAL_STATE,
} = actions;

const initState = {
  categories: [],
  products: [],
  loading: false,
  error: null,
  createOrderResult: {},
};

/**
 *
 * @todo impure state mutation/explaination
 */
const PosReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: data,
        error: null,
        loading: false,
        products: [],
      };
    case GET_CATEGORIES_BEGIN:
      return {
        ...state,
        categories: [],
        error: null,
        products: [],
        loading: true,
        createOrderResult: initState.createOrderResult,
      };
    case GET_CATEGORIES_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        loading: false,
        products: data,
      };
    case GET_PRODUCTS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_PRODUCTS_BEGIN:
      return {
        ...state,
        error: null,
        products: [],
        loading: true,
        createOrderResult: initState.createOrderResult,
      };
    case SEND_ORDER_BEGIN:
      return {
        ...state,
        loading: true,
        createOrderResult: initState.createOrderResult,
      };
    case SEND_ORDER_ERR:
      return {
        ...state,
        loading: false,
        error: err,
        createOrderResult: { success: true, error: err },
      };
    case SEND_ORDER_SUCCEED:
      return {
        ...state,
        error: null,
        products: [],
        loading: false,
        createOrderResult: { success: true, error: '', orderCreated: data },
      };
    case SET_INITIAL_STATE:
      return initState;
    default:
      return state;
  }
};
export default PosReducer;
