import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api/index';

const {
  getProducts,
  getProductsError,
  getProductsBegin,
  createProducts,
  createProductsBegin,
  createProductsError,
  getCategories,
  getCategoriesError,
  getCategoriesBegin,
  getOptions,
  getOptionsError,
  getOptionsBegin,
} = actions;

const fetchProducts = params => {
  return async dispatch => {
    try {
      const { page = 1, limit = 10 } = params || {};
      dispatch(getProductsBegin());
      const response = await DataService.get(`${API.pos.products}/?page=${page}&limit=${limit}`);
      return dispatch(getProducts(response.data));
    } catch (err) {
      return dispatch(getProductsError(err?.message || 'Error al intentar obtener lista de productos.'));
    }
  };
};

const createProduct = params => {
  return async dispatch => {
    try {
      dispatch(createProductsBegin());
      await DataService.post(`${API.pos.products}/`, {
        name: params.name,
        price: params.price,
        category: params.category,
        options: params.options,
      });
      message.success('Producto creado con éxito');
      dispatch(createProducts());
      dispatch(fetchProducts());
    } catch (err) {
      return dispatch(createProductsError(err?.message || 'Error al intentar crear el producto'));
    }
  };
};

const updateProduct = params => {
  return async dispatch => {
    try {
      dispatch(createProductsBegin());
      await DataService.patch(`${API.pos.products}/${params.idToUpdate}`, {
        name: params.name,
        price: params.price,
        category: params.category.id,
        options: params.options,
      });
      message.success('Producto actualizado correctamente');
      dispatch(createProducts());
      dispatch(fetchProducts());
    } catch (err) {
      return dispatch(createProductsError(err?.message || 'Error al intentar actualizar el producto'));
    }
  };
};

const fetchCategories = () => {
  return async dispatch => {
    try {
      dispatch(getCategoriesBegin());
      const response = await DataService.get(`${API.pos.categories}`);
      return dispatch(getCategories(response.data.results));
    } catch (err) {
      return dispatch(getCategoriesError(err?.message || 'Error al intentar obtener categorías'));
    }
  };
};

const fetchOptions = () => {
  return async dispatch => {
    try {
      dispatch(getOptionsBegin());
      const response = await DataService.get(`${API.pos.options}`);
      return dispatch(getOptions(response.data));
    } catch (err) {
      return dispatch(getOptionsError(err?.message || 'Error al intentar obtener las opciones'));
    }
  };
};

export { fetchProducts, createProduct, updateProduct, fetchCategories, fetchOptions };
