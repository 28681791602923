import actions from './actions';
const { GET_USERS, GET_USERS_ERR, GET_USERS_BEGIN, CREATE_USER, CREATE_USER_BEGIN, CREATE_USER_ERR } = actions;
const initState = {
  users: [],
  page: 1,
  totalResults: 0,
  limit: 0,
  loading: false,
  loadingCreate: false,
  error: null,
  filterUsers: [],
};

/**
 *
 * @todo impure state mutation/explaination
 */
const usersReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_USERS:
      return {
        ...state,
        loading: false,
        users: data.results,
        totalResults: data.totalResults,
        limit: data.limit,
        page: data.page,
      };
    case GET_USERS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_USERS_BEGIN:
      return {
        ...state,
        error: null,
        users: [],
        totalResults: initState.totalResults,
        limit: initState.limit,
        page: initState.page,
        loading: true,
      };
    case CREATE_USER:
      return {
        ...state,
        loadingCreate: false,
      };
    case CREATE_USER_BEGIN:
      return {
        ...state,
        loadingCreate: true,
      };
    case CREATE_USER_ERR:
      return {
        ...state,
        loadingCreate: false,
        error: err,
      };
    default:
      return state;
  }
};
export default usersReducer;
