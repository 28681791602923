import React, { useEffect, useState } from 'react';
import { Modal, Input, Spin, message } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { API } from '../../config/api';
import { DataService } from '../../config/dataService/dataService';

const Caja = () => {
  // eslint-disable-next-line
  const [state, setState] = useState({
    loading: true,
    showModal: false,
    corte: null,
    loadingSend: false,
  });

  const [cortePayload, setCortePayload] = useState({
    amount: 0,
    vasosCalienteMediano: 0,
    vasosCalienteGrande: 0,
    vasosFrioMediano: 0,
    vasosFrioGrande: 0,
  });

  const { loading, showModal, corte, loadingSend } = state;

  const sendAmount = async () => {
    setState({
      ...state,
      loading: true,
      loadingSend: true,
    });
    const { amount, vasosCalienteMediano, vasosCalienteGrande, vasosFrioMediano, vasosFrioGrande } = cortePayload;
    const url = `${API.pos.cortes}/${corte ? 'finalizarCorte' : ''}`;
    const payloadToSend = corte
      ? {
          amount,
          vasosCierreCalienteMediano: vasosCalienteMediano,
          vasosCierreCalienteGrande: vasosCalienteGrande,
          vasosCierreFrioMediano: vasosFrioMediano,
          vasosCierreFrioGrande: vasosFrioGrande,
        }
      : {
          fondoInicial: amount,
          vasosInicialCalienteMediano: vasosCalienteMediano,
          vasosInicialCalienteGrande: vasosCalienteGrande,
          vasosInicialFrioMediano: vasosFrioMediano,
          vasosInicialFrioGrande: vasosFrioGrande,
        };
    DataService.post(url, payloadToSend)
      .then(payload => {
        setState({
          ...state,
          loading: false,
          loadingSend: false,
          showModal: false,
          corte: corte ? null : payload?.data,
        });
      })
      .catch(err => {
        setState({ ...state, loading: false });
        // message.error(err?.message || 'Error al intentar actualizar la orden');
      });
  };

  const onClickSend = () => {
    if (cortePayload.amount <= 0) return message.warning('Debes capturar una cantidad en el monto');
    if (corte) {
      const confirm = window.confirm('Estas seguro de cerrar el corte?. Esto evitará que sigan operando el sistema.');
      if (!confirm) return;
    }
    sendAmount();
  };

  const toggleShowModal = () => {
    console.log('showModal', showModal);

    setState({ ...state, showModal: !showModal });
  };

  const fetchCorte = async () => {
    setState({
      ...state,
      loading: true,
    });
    DataService.get(`${API.pos.cortes}/me`)
      .then(payload => {
        console.log('payload', payload?.data);
        setState({ ...state, loading: false, corte: payload?.data });
      })
      .catch(err => {
        setState({ ...state, loading: false });
        // message.error(err?.message || 'Error al intentar actualizar la orden');
      });
  };

  useEffect(() => {
    fetchCorte().catch(console.log);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Main>
        <PageHeader ghost title="Movimiento De Caja" buttons={[]} />
        <Modal
          type="info"
          title={corte ? 'Capturar Cierre Caja' : 'Capturar Fondo Inicial'}
          visible={showModal}
          onOk={onClickSend}
          confirmLoading={loadingSend}
          onCancel={toggleShowModal}
          okText="Enviar"
          cancelText="Cancelar"
        >
          <div className="input-form-corte">
            <span className="summary-list-title">Monto :</span>
            <Input
              size={'middle'}
              type={'number'}
              placeholder="Cantidad"
              onChange={e => setCortePayload({ ...cortePayload, amount: e?.target?.value })}
              value={cortePayload.amount}
            />
          </div>
          <div className="input-form-corte">
            <span className="summary-list-title">Vasos Caliente Mediano (12 Oz):</span>
            <Input
              size={'middle'}
              type={'number'}
              placeholder="Cantidad Mediano Caliente 12 Oz"
              onChange={e => setCortePayload({ ...cortePayload, vasosCalienteMediano: e?.target?.value })}
              value={cortePayload.vasosCalienteMediano}
            />
          </div>
          <div className="input-form-corte">
            <span className="summary-list-title">Vasos Caliente Grande (16 Oz) :</span>
            <Input
              size={'middle'}
              type={'number'}
              placeholder="Cantidad"
              onChange={e => setCortePayload({ ...cortePayload, vasosCalienteGrande: e?.target?.value })}
              value={cortePayload.vasosCalienteGrande}
            />
          </div>
          <div className="input-form-corte">
            <span className="summary-list-title">Vasos Frio Mediano (16 Oz):</span>
            <Input
              size={'middle'}
              type={'number'}
              placeholder="Cantidad Mediano Frio 16 Oz"
              onChange={e => setCortePayload({ ...cortePayload, vasosFrioMediano: e?.target?.value })}
              value={cortePayload.vasosFrioMediano}
            />
          </div>
          <div className="input-form-corte">
            <span className="summary-list-title">Vasos Frio Grande (20 Oz) :</span>
            <Input
              size={'middle'}
              type={'number'}
              placeholder="Cantidad Mediano Frio 20 Oz"
              onChange={e => setCortePayload({ ...cortePayload, vasosFrioGrande: e?.target?.value })}
              value={cortePayload.vasosFrioGrande}
            />
          </div>
        </Modal>
        <Cards>
          {loading ? (
            <Spin />
          ) : (
            <Button
              disabled={loading}
              size="default"
              onClick={toggleShowModal}
              shape="circle"
              type={corte ? 'dark' : 'success'}
            >
              <FeatherIcon icon="dollar-sign" size={14} />
              {corte ? 'Capturar Cierre Caja' : 'Capturar Fondo Inicial'}
            </Button>
          )}
        </Cards>
      </Main>
    </>
  );
};

export default Caja;
