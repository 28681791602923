import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api/index';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = data => {
  return async dispatch => {
    try {
      dispatch(loginBegin());
      const response = await DataService.post(`auth${API.auth.login}`, data);
      localStorage.setItem('user', JSON.stringify(response.data));
      return dispatch(loginSuccess(response.data));
    } catch (err) {
      return dispatch(loginErr(err?.message || 'Error al intentar iniciar sesión'));
    }
  };
};

const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      localStorage.removeItem('user');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut };
