import { combineReducers } from 'redux';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import posReducer from './pos/reducers';
import ordersReducer from './orders/reducers';
import cortesReducer from './cortes/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import usersReducer from './users/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import clientsReducer from './clients/reducers';
import productsReducer from './products/reducers';
import cardsReducer from './cards/reducers';
import rechargesReducer from './recharges/reducers';

const rootReducers = combineReducers({
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  users: usersReducer,
  auth: authReducer,
  pos: posReducer,
  orders: ordersReducer,
  cortes: cortesReducer,
  clients: clientsReducer,
  products: productsReducer,
  cards: cardsReducer,
  card: cardsReducer,
  ChangeLayoutMode,
  recharges: rechargesReducer,
});

export default rootReducers;
