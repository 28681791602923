import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api/index';

const { getCortes, getCortesError, getCortesBegin } = actions;

const fetchCortes = params => {
  return async dispatch => {
    try {
      const { page = 1, limit = 10 } = params || {};
      dispatch(getCortesBegin());
      const response = await DataService.get(`${API.pos.cortes}/?sortBy=createdAt:desc&page=${page}&limit=${limit}`);
      return dispatch(getCortes(response.data));
    } catch (err) {
      return dispatch(getCortesError(err?.message || 'Error al intentar obtener categorías'));
    }
  };
};

export { fetchCortes };
