import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api/index';

const {
  getRecharges,
  getRechargesError,
  getRechargesBegin,
  filterRechargeBegin,
  filterRechargeSuccess,
  filterRechargeErr,
} = actions;

const fetchRecharges = params => {
  return async dispatch => {
    try {
      const { page = 1, limit = 25 } = params || {};
      dispatch(getRechargesBegin());
      const pagination = `page=${page}&limit=${limit}`;
      const filter = `?${pagination}`;
      const response = await DataService.get(`${API.pos.recargas}/history/${filter}`);
      return dispatch(getRecharges(response.data));
    } catch (err) {
      return dispatch(getRechargesError(err?.message || 'Error al intentar obtener categorías'));
    }
  };
};

const rechargeFilter = (column, value) => {
  return async (dispatch, getState) => {
    try {
      dispatch(filterRechargeBegin());
      const recharges = getState().recharges.recharges;
      if (!column) {
        dispatch(filterRechargeSuccess([]));
      } else {
        const data = recharges.filter(item => {
          if (value !== '') {
            return item[column] === value;
          }
          return item;
        });
        dispatch(filterRechargeSuccess(data));
      }
    } catch (err) {
      dispatch(filterRechargeErr(err));
    }
  };
};

export { fetchRecharges, rechargeFilter };
