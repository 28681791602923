const actions = {
  GET_ORDERS_BEGIN: 'GET_ORDERS_BEGIN',
  GET_ORDERS: 'GET_ORDERS',
  GET_ORDERS_ERR: 'GET_ORDERS_ERR',
  GET_CUSTOMERS_BEGIN: 'GET_CUSTOMERS_BEGIN',
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMERS_ERR: 'GET_CUSTOMERS_ERR',
  FILTER_ORDER_BEGIN: 'FILTER_ORDER_BEGIN',
  FILTER_ORDER_SUCCESS: 'FILTER_ORDER_SUCCESS',
  FILTER_ORDER_ERR: 'FILTER_ORDER_ERR',

  getOrders: data => {
    return {
      type: actions.GET_ORDERS,
      data,
    };
  },

  getOrdersBegin: () => {
    return {
      type: actions.GET_ORDERS_BEGIN,
    };
  },

  getOrdersError: err => {
    return {
      type: actions.GET_ORDERS_ERR,
      err,
    };
  },
  getCustomers: data => {
    return {
      type: actions.GET_CUSTOMERS,
      data,
    };
  },

  getCustomersBegin: () => {
    return {
      type: actions.GET_CUSTOMERS_BEGIN,
    };
  },

  getCustomersError: err => {
    return {
      type: actions.GET_CUSTOMERS_ERR,
      err,
    };
  },
  filterOrderBegin: () => {
    return {
      type: actions.FILTER_ORDER_BEGIN,
    };
  },

  filterOrderSuccess: data => {
    return {
      type: actions.FILTER_ORDER_SUCCESS,
      data,
    };
  },

  filterOrderErr: err => {
    return {
      type: actions.FILTER_ORDER_ERR,
      err,
    };
  },
};

export default actions;
