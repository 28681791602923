import { isEmpty, keys, get } from 'lodash';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api/index';

const {
  getCategories,
  getCategoriesError,
  getProducts,
  getProductsError,
  getProductsBegin,
  getCategoriesBegin,
  sendOrderBegin,
  sendOrderError,
  sendOrderSucceed,
  setInitialState,
} = actions;

const fetchCategories = () => {
  return async dispatch => {
    try {
      dispatch(getCategoriesBegin());
      const response = await DataService.get(`${API.pos.categories}`);
      return dispatch(getCategories(response.data.results));
    } catch (err) {
      return dispatch(getCategoriesError(err?.message || 'Error al intentar obtener categorías'));
    }
  };
};

const fetchProducts = categoryId => {
  return async dispatch => {
    try {
      dispatch(getProductsBegin());
      const response = await DataService.get(`${API.pos.products}/?category=${categoryId}`);
      return dispatch(getProducts(response.data.results));
    } catch (err) {
      return dispatch(getProductsError(err?.message || 'Error al intentar obtener categorías'));
    }
  };
};

const sendOrder = (data = {}) => {
  return async dispatch => {
    try {
      dispatch(sendOrderBegin());
      const { products = [], customerName = '', note } = data;
      if (isEmpty(products)) {
        throw new Error('Necesitas agregar al menos un artículo para enviar la orden');
      }
      const parsedProducts = products.map(product => {
        const { id: productId, productOptions = {}, productOptionsCount = {}, quantity = 1 } = product;
        const optionsProductSend = keys(productOptions).map(productOptionKey => {
          const productOption = productOptions[productOptionKey];
          const optionSelected = get(productOption, 'optionSelected', []);
          return { optionId: productOptionKey, optionSelected };
        });

        const optionsCounter = keys(productOptionsCount).map(productOptionCountKey => {
          const productOptionCount = productOptionsCount[productOptionCountKey];
          const optionCounterQuantityAll = (productOptionCount?.optionCounterQuantity || []).map(itemCount => {
            return { optionCountId: itemCount._id, optionQuantity: itemCount.quantity };
          });

          const optionCounterQuantity = optionCounterQuantityAll.filter(
            itemCountToSend => itemCountToSend.optionQuantity > 0,
          );
          return { optionId: productOptionCount.optionId, optionCounterQuantity };
        });
        const optionsCounterFiltered = optionsCounter.filter(
          optionsCounter => optionsCounter?.optionCounterQuantity?.length,
        );

        const options = optionsProductSend.filter(optionProduct => !isEmpty(optionProduct.optionSelected));

        return { productId, options, optionsCounter: optionsCounterFiltered, quantity };
      });

      const orderData = { products: parsedProducts, customerName };
      if (note) orderData.note = note;
      const result = await DataService.post(`${API.pos.orders}/`, orderData);
      return dispatch(sendOrderSucceed(result.data));
    } catch (err) {
      //message.error(err?.message || 'Error al enviar la orden');
      return dispatch(sendOrderError(err?.message || 'Error al enviar la orden'));
    }
  };
};

const initializeState = () => {
  return async dispatch => {
    return dispatch(setInitialState());
  };
};

export { fetchCategories, fetchProducts, sendOrder, initializeState };
