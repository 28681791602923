import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Modal, Table, Form, Input, Spin, Select, Popconfirm } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../page-headers/page-headers';
import { Main, TableWrapper, BasicFormWrapper, AddUser } from '../../container/styled';
import { Button } from '../buttons/buttons';
import { Cards } from '../cards/frame/cards-frame';
import { createProduct, updateProduct, fetchCategories, fetchOptions } from '../../redux/products/actionCreator';

const { Option } = Select;

const ProductsTable = ({ pageNumber, onChangePageNumber, onDeleteProduct }) => {
  const { categories = [] } = useSelector(state => state.pos);
  const { options = {} } = useSelector(state => state.products);
  const { filterProducts, products = [], loadingCreate, total = 0 } = useSelector(state => {
    return {
      filterProducts: state.products.filterProducts,
      products: state.products.products,
      loadingCreate: state.products.loadingCreate,
      total: state.products.totalResults,
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOptions());
    dispatch(fetchCategories());
  }, [dispatch]);
  const [form] = Form.useForm();

  const [state, setState] = useState({
    notData: filterProducts,
    selectedRowKeys: [],
    update: { name: '', price: 0, category: { id: '', name: '' }, options: {} },
    modalVisible: false,
    modalUpdateVisible: false,
    idToUpdate: '',
    optionsArray: [],
  });

  const handleOnChangePage = params => {
    onChangePageNumber(params);
  };
  const handleOnDeleteUser = id => {
    onDeleteProduct(id);
  };
  const handleCancel = () => {
    setState({ ...state, modalVisible: false, modalUpdateVisible: false });
  };

  const handleOk = data => {
    dispatch(createProduct(data));
    onChangePageNumber(1);
  };

  const handleOkDataUpdate = form => {
    console.log('form', form);

    dispatch(updateProduct({ ...form, idToUpdate: state.idToUpdate }));
    onChangePageNumber(1);
  };

  const toggleModal = () => {
    setState({ ...state, modalVisible: !state.modalVisible });
  };

  const handleOnShowDataUpdate = (idToUpdate, name, price, category, opciones) => {
    setState({
      ...state,
      modalUpdateVisible: !state.modalUpdateVisible,
      idToUpdate,
      update: { name, price: `${price}`, category, options: opciones },
      optionsArray: opciones.map(option => option.id),
    });
    form.setFieldsValue({
      name,
      price: `${price}`,
      category: category?.id,
      options: opciones.map(option => option.id),
    });
  };

  const handleOnChangeOptionSelect = value => {
    setState({
      ...state,
      update: { ...state.update, options: value },
      optionsArray: value,
    });
    form.setFieldsValue({
      options: value,
    });
  };

  const closeModalUpdate = () => {
    setState({
      ...state,
      modalUpdateVisible: !state.modalUpdateVisible,
      idToUpdate: '',
      update: { name: '', price: '0', category: { id: '', name: '' }, options: [] },
      optionsArray: [],
    });
    form.setFieldsValue({ name: '', price: '0', category: '', options: [] });
  };

  const dataSource = [];
  if (products?.length) {
    let iteareProp = products;
    if (filterProducts?.length > 0) iteareProp = filterProducts;
    iteareProp.map((value, key) => {
      const { id, name, price, category, options: opciones } = value;
      return dataSource.push({
        key: key + 1,
        id: <span className="product-id">{id.substring(8)}</span>,
        name: <span className="name">{name}</span>,
        price: <span className="price">${price}</span>,
        category: <span className="category">{category.name}</span>,
        options: <span className="options">{opciones.map(option => `| ${option.name} |`)}</span>,
        action: (
          <div className="table-actions">
            <>
              <Button
                className="btn-icon"
                type="warning"
                onClick={() => handleOnShowDataUpdate(id, name, price, category, opciones)}
                shape="circle"
              >
                <FeatherIcon icon="edit" size={16} />
              </Button>
              <Popconfirm
                title="¿Seguro que quieres borrar este producto?"
                onConfirm={() => {
                  handleOnDeleteUser(id);
                }}
                okText="Si"
                cancelText="No"
              >
                <Button className="btn-icon" type="danger" shape="circle">
                  <FeatherIcon icon="trash-2" size={16} />
                </Button>
              </Popconfirm>
              
            </>
          </div>
        ),
      });
    });
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Opciones',
      dataIndex: 'options',
      key: 'options',
    },
    {
      title: 'Acción',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  return (
    <>
      <PageHeader
        ghost
        title="Productos"
        buttons={[
          <div key="1" className="page-header-actions">
            <Button className="btn-add_new" size="default" key="1" type="primary" onClick={toggleModal}>
              <FeatherIcon icon="plus-square" size={14} /> Agregar Producto
            </Button>
          </div>,
        ]}
      />
      <Main>
        <Modal title="Información del producto" visible={state.modalVisible} footer={null} onCancel={handleCancel}>
          <div className="project-modal">
            <BasicFormWrapper>
              <AddUser>
                <Form form={form} name="product" onFinish={handleOk}>
                  <Form.Item label="Nombre" name="name" rules={[{ required: true, message: 'Ingresa un nombre' }]}>
                    <Input placeholder="Nombre del producto" />
                  </Form.Item>

                  <Form.Item
                    label="Precio"
                    name="price"
                    rules={[{ required: true, message: 'Ingresa un precio para el producto', type: 'string' }]}
                  >
                    <Input placeholder="$55" />
                  </Form.Item>

                  <Form.Item label="Categoría" name="category">
                    <Select
                      placeholder="Categoría"
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: 220 }}
                      rules={[{ required: true, message: 'Selecciona una categoría' }]}
                    >
                      {categories.map(category => (
                        <Option key={category.id} value={category.id}>{`${category.name}`}</Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item label="Opciones" name="options">
                    <Select
                      placeholder="Opciones"
                      mode="multiple"
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: 220 }}
                      rules={[{ required: false, message: 'Selecciona las opciones requeridas' }]}
                    >
                      {options.map(option => (
                        <Option key={option.id} value={option.id}>{`${option.name}`}</Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {loadingCreate ? (
                    <Spin />
                  ) : (
                    <Button htmlType="submit" size="default" type="primary" key="submit">
                      Agregar Nuevo Producto
                    </Button>
                  )}
                </Form>
              </AddUser>
            </BasicFormWrapper>
          </div>
        </Modal>

        <Modal title="Actualizar producto" visible={state.modalUpdateVisible} footer={null} onCancel={closeModalUpdate}>
          <div className="project-modal">
            <BasicFormWrapper>
              <AddUser>
                <Form form={form} name="updateData" onFinish={handleOkDataUpdate}>
                  <Form.Item label="Nombre" name="name" rules={[{ required: false, message: 'Ingresa un nombre' }]}>
                    <div>
                      <Input
                        placeholder="Nombre del producto"
                        onChange={value =>
                          setState({ ...state, update: { ...state.update, name: value?.target?.value } })
                        }
                        value={state.update.name}
                      />
                    </div>
                  </Form.Item>

                  <Form.Item
                    label="Precio"
                    name="price"
                    rules={[{ required: false, message: 'Ingresa un precio para el producto', type: 'string' }]}
                  >
                    <div>
                      <Input
                        placeholder="$55"
                        onChange={value =>
                          setState({ ...state, update: { ...state.update, price: value?.target?.value } })
                        }
                        value={state.update.price}
                      />
                    </div>
                  </Form.Item>

                  <Form.Item label="Categoría" name="category">
                    <div>
                      <Select
                        placeholder="Categoría"
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 220 }}
                        rules={[{ required: false, message: 'Selecciona una categoría' }]}
                        onSelect={value => {
                          setState({
                            ...state,
                            update: { ...state.update, category: { id: value } },
                          });
                        }}
                        value={state.update.category.id}
                      >
                        {categories.map(category => (
                          <Option key={category.id} value={category.id}>{`${category.name}`}</Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>

                  <Form.Item label="Opciones" name="options">
                    <div>
                      <Select
                        placeholder="Opciones"
                        mode="multiple"
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 220 }}
                        rules={[{ required: false, message: 'Selecciona las opciones requeridas' }]}
                        onChange={handleOnChangeOptionSelect}
                        value={state.optionsArray}
                      >
                        {options.map(option => (
                          <Option key={option.id} value={option.id}>{`${option.name}`}</Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>

                  {loadingCreate ? (
                    <Spin />
                  ) : (
                    <Button htmlType="submit" size="default" type="primary" key="submit">
                      Actualizar Producto
                    </Button>
                  )}
                </Form>
              </AddUser>
            </BasicFormWrapper>
          </div>
        </Modal>
        <Cards headless>
          <Row gutter={15}>
            <Col md={24}>
              <TableWrapper className="table-user table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{
                    current: pageNumber,
                    onChange: handleOnChangePage,
                    total,
                  }}
                />
              </TableWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

ProductsTable.propTypes = {
  pageNumber: PropTypes.number,
  onChangePageNumber: PropTypes.func,
  onDeleteProduct: PropTypes.func,
};
export default ProductsTable;
