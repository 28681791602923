import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import CardsTable from '../../components/cardTable/CardTable';
import { fetchCards } from '../../redux/cards/actionCreator';

const CardsHistory = () => {
  const { loading } = useSelector(state => state.cards);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    pageNumber: 1,
    searchText: '',
  });

  const { pageNumber, searchText } = state;
  useEffect(() => {
    dispatch(fetchCards({ page: pageNumber, searchText }));
  }, [pageNumber, searchText, dispatch]);

  const handleOnChangePageNumber = number => {
    setState({ ...state, pageNumber: number });
  };

  const handleOnSearchCards = text => {
    setState({ ...state, pageNumber: 1, searchText: text });
  };

  return (
    <>
      {loading ? (
        <Spin size="large" />
      ) : (
        <CardsTable
          pageNumber={pageNumber}
          onChangePageNumber={handleOnChangePageNumber}
          onSearchCards={handleOnSearchCards}
          searchText={searchText}
        />
      )}
    </>
  );
};

export default CardsHistory;
