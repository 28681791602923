import React from 'react';
import { Table, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { FigureCart, ProductTable } from '../../container/styled';
import Heading from '../heading/heading';
import { Button } from '../buttons/buttons';

const CartTable = props => {
  const { products, onRemoveItem, decrementUpdate, incrementUpdate } = props;

  const cartDeleted = id => {
    onRemoveItem(id);
  };

  const productTableData = [];

  if (products !== null) {
    let totalQty = 0;
    let totalPrice = 0;
    products.map((data, index) => {
      const { id, img = 'static/img/products/2.png', name, quantity = 1, finalPrice: price } = data;
      totalQty += quantity;
      totalPrice += price * quantity;
      return productTableData.push({
        key: id + index,
        product: (
          <div className="cart-single">
            <FigureCart>
              <img style={{ width: 80 }} src={require(`../../${img}`)} alt="" />
              <figcaption>
                <div className="cart-single__info">
                  <Heading as="h6">{name}</Heading>
                  <ul className="info-list">
                    {(data.viewOptions || []).map((option, index) => {
                      return (
                        <li key={index + option.name}>
                          <span className="info-title">{option.name} :</span>
                          <span>{(option?.optionsSelected || []).join(',')}</span>
                        </li>
                      );
                    })}
                    {(data.counterOptions || []).map((option, index) => {
                      return (
                        <li key={index + option.title}>
                          <span className="info-title">{option.title} :</span>
                          <span>{option.optionCounterQuantity}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </figcaption>
            </FigureCart>
          </div>
        ),
        price: <span className="cart-single-price">${price}</span>,
        quantity: (
          <div className="cart-single-quantity">
            <Button onClick={() => decrementUpdate(index, quantity)} className="btn-dec" type="default">
              <FeatherIcon icon="minus" size={12} />
            </Button>
            {quantity}
            <Button onClick={() => incrementUpdate(index, quantity)} className="btn-inc" type="default">
              <FeatherIcon icon="plus" size={12} />
            </Button>
          </div>
        ),
        total: <span className="cart-single-t-price">${quantity * price}</span>,
        action: (
          <div className="table-action">
            <Popconfirm
              title="¿Seguro que quieres borrar este artículo?"
              onConfirm={() => cartDeleted(id)}
              okText="Si"
              cancelText="No"
            >
              <Button className="btn-icon" to="#" size="default" type="danger" shape="circle" transparented>
                <FeatherIcon icon="trash-2" size={16} />
              </Button>
            </Popconfirm>
          </div>
        ),
      });
    });
    productTableData.push({
      key: 'totalRow',
      price: <span className="cart-single-price">Total</span>,
      quantity: <span className="cart-single-price">{totalQty}</span>,
      total: <span className="cart-single-t-price">${totalPrice}</span>,
    });
  }

  const productTableColumns = [
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  return (
    <>
      <ProductTable>
        <div className="table-cart table-responsive">
          <Table pagination={false} dataSource={productTableData} columns={productTableColumns} />
        </div>
      </ProductTable>
    </>
  );
};

CartTable.defaultProps = {
  products: [],
  onRemoveItem: PropTypes.func,
};

CartTable.propTypes = {
  products: PropTypes.array,
  onRemoveItem: PropTypes.func,
  decrementUpdate: PropTypes.func,
  incrementUpdate: PropTypes.func,
};

export default CartTable;
