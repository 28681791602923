const actions = {
  GET_RECHARGES_BEGIN: 'GET_RECHARGES_BEGIN',
  GET_RECHARGES: 'GET_RECHARGES',
  GET_RECHARGES_ERR: 'GET_RECHARGES_ERR',
  GET_CUSTOMERS_BEGIN: 'GET_CUSTOMERS_BEGIN',
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMERS_ERR: 'GET_CUSTOMERS_ERR',
  FILTER_ORDER_BEGIN: 'FILTER_ORDER_BEGIN',
  FILTER_ORDER_SUCCESS: 'FILTER_ORDER_SUCCESS',
  FILTER_ORDER_ERR: 'FILTER_ORDER_ERR',

  getRecharges: data => {
    return {
      type: actions.GET_RECHARGES,
      data,
    };
  },

  getRechargesBegin: () => {
    return {
      type: actions.GET_RECHARGES_BEGIN,
    };
  },

  getRechargesError: err => {
    return {
      type: actions.GET_RECHARGES_ERR,
      err,
    };
  },
  getCustomers: data => {
    return {
      type: actions.GET_CUSTOMERS,
      data,
    };
  },

  getCustomersBegin: () => {
    return {
      type: actions.GET_CUSTOMERS_BEGIN,
    };
  },

  getCustomersError: err => {
    return {
      type: actions.GET_CUSTOMERS_ERR,
      err,
    };
  },
  filterRechargeBegin: () => {
    return {
      type: actions.FILTER_ORDER_BEGIN,
    };
  },

  filterRechargeSuccess: data => {
    return {
      type: actions.FILTER_ORDER_SUCCESS,
      data,
    };
  },

  filterRechargeErr: err => {
    return {
      type: actions.FILTER_ORDER_ERR,
      err,
    };
  },
};

export default actions;
