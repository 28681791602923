import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api/index';

const {
  getCards,
  getCard,
  resetCard,
  getCardsError,
  getCardsBegin,
  getCardBegin,
  createCards,
  createCardsBegin,
  createCardsError,
  rechargeCards,
  rechargeCardsBegin,
  rechargeCardsError,
} = actions;

const fetchCards = params => {
  return async dispatch => {
    try {
      const { page = 1, limit = 10, searchText = '' } = params || {};
      dispatch(getCardsBegin());
      const response = await DataService.get(
        `${API.pos.cards}/?sortBy=createdAt:desc&page=${page}&limit=${limit}&searchText=${searchText}`,
      );
      return dispatch(getCards(response.data));
    } catch (err) {
      return dispatch(getCardsError(err?.message || 'Error al intentar obtener lista de cards.'));
    }
  };
};

const fetchCardById = cardId => {
  return async dispatch => {
    try {
      dispatch(getCardBegin());
      const response = await DataService.get(`${API.pos.cards}/cardname/${cardId}`);
      return dispatch(getCard(response.data));
    } catch (err) {
      return dispatch(getCardsError(err?.message || 'Error al intentar obtener la card.'));
    }
  };
};

const resetCardAction = () => {
  return async dispatch => {
    dispatch(resetCard());
  };
};

const createCard = params => {
  return async dispatch => {
    try {
      console.log('params',params);
      dispatch(createCardsBegin());
      await DataService.post(`${API.pos.cards}/`, {
        name: params.name,
        cellphone: params.cellphone,
        email: params.email,
        cardname: params.cardname,
        description: params.description,
        montoRecarga: params.montoRecarga,
      });
      message.success('Tarjeta agregada con éxito');
      dispatch(createCards());
      dispatch(fetchCards());
    } catch (err) {
      dispatch(createCardsError(err?.message || 'Error al intentar agregar la tarjeta'));
    }
  };
};

const rechargeCard = params => {
  return async dispatch => {
    try {
      console.log('params', params);

      dispatch(rechargeCardsBegin());
      await DataService.patch(`${API.pos.cards}/${params.idToRecharge}/recharge`, params);
      message.success('Recarga con éxito');
      dispatch(rechargeCards());
      dispatch(fetchCards());
    } catch (err) {
      dispatch(rechargeCardsError(err?.message || 'Error al intentar agregar la tarjeta'));
    }
  };
};

export { fetchCards, fetchCardById, createCard, rechargeCard, resetCardAction };
