import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api/index';

const { getClients, getClientsError, getClientsBegin, createClients, createClientsBegin, createClientsError } = actions;

const fetchClients = params => {
  return async dispatch => {
    try {
      const { page = 1, limit = 10 } = params || {};
      dispatch(getClientsBegin());
      const response = await DataService.get(`${API.pos.clients}/?sortBy=createdAt:desc&page=${page}&limit=${limit}`);
      return dispatch(getClients(response.data));
    } catch (err) {
      return dispatch(getClientsError(err?.message || 'Error al intentar obtener lista de clientes.'));
    }
  };
};

const createClient = params => {
  return async dispatch => {
    try {
      dispatch(createClientsBegin());
      await DataService.post(`${API.pos.clients}/`, {
        name: params.name,
        cellphone: params.cellphone,
        email: params.email,
        discount: params.discount,
      });
      message.success('Cliente creado con éxito');
      dispatch(createClients());
      dispatch(fetchClients());
    } catch (err) {
      return dispatch(createClientsError(err?.message || 'Error al intentar crear el cliente'));
    }
  };
};

export { fetchClients, createClient };
