import React, { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Button } from '../../components/buttons/buttons';
import OrderCard from '../../components/orderCard/orderCard';
import { Main } from '../styled';
import { fetchOrders } from '../../redux/orders/actionCreator';
import { fetchClients } from '../../redux/clients/actionCreator';
import { fetchCards } from '../../redux/cards/actionCreator';
import { fetchPrinterConfig } from '../../utility/utility';

const Orders = () => {
  const { orders = [], loading } = useSelector(state => state.orders);
  const { clients = [] } = useSelector(state => state.clients);
  const { cards = [] } = useSelector(state => state.cards);
  const { role = '' } = useSelector(state => state.auth.login.user);
  const dispatch = useDispatch();

  const [ipName, setIpName] = useState('')

  const fetchPrinterConfiguration = async () => {
    const ipName = await fetchPrinterConfig()
    setIpName(ipName)
      
  };


  useEffect(() => {
    console.log('useEffect ipConfig')
    if(!ipName){
      fetchPrinterConfiguration()
    }
  }, [ipName])

  useEffect(() => {
    dispatch(fetchOrders());
    dispatch(fetchClients());
    dispatch(fetchCards());
  }, [dispatch]);

  const onChangeOrder = () => {
    dispatch(fetchOrders());
  };

  const onClickUpdateOrders = () => {
    dispatch(fetchOrders());
  };

  useEffect(() => {
    if (role === 'pantalla') {
      const interval = setInterval(() => {
        dispatch(fetchOrders());
      }, 9000);
      return () => clearInterval(interval);
    }
  }, [role, dispatch]);

  const renderOrders = () => {
    const driveThruOrders = [];
    const normalOrders = [];
    // let exceededDrive = 0;
    orders.forEach(order => {
      if (order?.orderUserType === 'drive-thru') driveThruOrders.push(order);
      else normalOrders.push(order);
    });
    // if (driveThruOrders.length > 4) {
    //   exceededDrive = driveThruOrders.length - 4;
    // }
    const ordersMixed = [...driveThruOrders.slice(0, 4), ...normalOrders];

    return ordersMixed.map(order => {
      return (
        <Col key={order.id} xl={6} lg={8} md={12} sm={24} xs={24}>
          <OrderCard
            ipName={ipName}
            clients={clients}
            order={order}
            cards={cards}
            onSuccessChangeOrder={() => onChangeOrder(order.id)}
            showActions={role !== 'pantalla'}
          />
        </Col>
      );
    });
  };

  return (
    <>
      <PageHeader
        ghost
        title="Ordenes"
        subTitle={
          <>
            <Button disabled={loading} size="default" onClick={onClickUpdateOrders} shape="circle" type="dark">
              {loading ? <Spin /> : <FeatherIcon icon="refresh-cw" size={14} />}
              Actualizar Órdenes
            </Button>
          </>
        }
      />
      <Main>
        <Cards title="Ordenes Pendientes" size="large">
          {loading ? (
            <Spin size="large" />
          ) : (
            <div className="columnCardsWrapper">
              <Row gutter={16}>{renderOrders()}</Row>
            </div>
          )}
        </Cards>
      </Main>
    </>
  );
};

export default Orders;
