const actions = {
  GET_CARD_BEGIN: 'GET_CARD_BEGIN',
  GET_CARD: 'GET_CARD',
  GET_CARDS_BEGIN: 'GET_CARDS_BEGIN',
  GET_CARDS: 'GET_CARDS',
  GET_CARDS_ERR: 'GET_CARDS_ERR',
  CREATE_CARDS: 'CREATE_CARDS',
  CREATE_CARDS_BEGIN: 'CREATE_CARDS_BEGING',
  CREATE_CARDS_ERR: 'CREATE_CARDS_ERR',
  RECHARGE_CARDS: 'RECHARGE_CARDS',
  RECHARGE_CARDS_BEGIN: 'RECHARGE_CARDS_BEGING',
  RECHARGE_CARDS_ERR: 'RECHARGE_CARDS_ERR',
  RESET_CARD: 'RESET_CARD',

  getCard: data => {
    return {
      type: actions.GET_CARD,
      data,
    };
  },

  getCardBegin: () => {
    return {
      type: actions.GET_CARD_BEGIN,
    };
  },

  resetCard: () => {
    return {
      type: actions.RESET_CARD,
    };
  },

  getCards: data => {
    return {
      type: actions.GET_CARDS,
      data,
    };
  },

  getCardsBegin: () => {
    return {
      type: actions.GET_CARDS_BEGIN,
    };
  },

  getCardsError: err => {
    return {
      type: actions.GET_CARDS_ERR,
      err,
    };
  },

  createCards: () => {
    return {
      type: actions.CREATE_CARDS,
    };
  },
  createCardsBegin: () => {
    return {
      type: actions.CREATE_CARDS_BEGIN,
    };
  },

  createCardsError: err => {
    return {
      type: actions.CREATE_CARDS_ERR,
      err,
    };
  },
  rechargeCards: () => {
    return {
      type: actions.RECHARGE_CARDS,
    };
  },
  rechargeCardsBegin: () => {
    return {
      type: actions.RECHARGE_CARDS_BEGIN,
    };
  },

  rechargeCardsError: err => {
    return {
      type: actions.RECHARGE_CARDS_ERR,
      err,
    };
  },
};

export default actions;
