import React, { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ProductsTable from '../../components/productsTable/ProductsTable';
import { fetchProducts } from '../../redux/products/actionCreator';
import { API } from '../../config/api';
import { DataService } from '../../config/dataService/dataService';

const Products = () => {
  const { loading } = useSelector(state => state.products);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [state, setState] = useState({ loadingScreen: false });


  useEffect(() => {
    dispatch(fetchProducts({ page: pageNumber }));
  }, [pageNumber, dispatch]);

  const handleOnChangePageNumber = number => {
    setPageNumber(number);
  };

  const handleOnDeleteProduct = async idToRemove => {
    setState({
     ...state,
      loading: true,
    });

    DataService.delete(`${API.pos.products}/${idToRemove}`)
     .then(_data => {
        message.success('Producto Eliminado');
        setState({...state, loading: false });
        dispatch(fetchProducts({ page: pageNumber }));
      })
     .catch(_err => {
        setState({...state, loading: false });
      });
  };
  

  return (
    <>
      {loading ? (
        <Spin size="large" />
      ) : (
        <ProductsTable pageNumber={pageNumber} onChangePageNumber={handleOnChangePageNumber} onDeleteProduct={handleOnDeleteProduct} />
      )}
    </>
  );
};

export default Products;
