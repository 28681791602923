import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const login = useSelector(state => state.auth.login);
  return (
    <Menu
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
    >
      <Menu.Item icon={!topMenu && <FeatherIcon icon="home" />} key="home">
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Inicio
        </NavLink>
      </Menu.Item>
      {login?.user?.role === 'pantalla' ? null : (
        <Menu.Item icon={!topMenu && <FeatherIcon icon="shopping-cart" />} key="pos">
          <NavLink onClick={toggleCollapsed} to={`${path}/pos`}>
            Punto de Venta
          </NavLink>
        </Menu.Item>
      )}

      <Menu.Item icon={!topMenu && <FeatherIcon icon="shopping-bag" />} key="ordenes">
        <NavLink onClick={toggleCollapsed} to={`${path}/ordenes`}>
          Ordenes Realizadas
        </NavLink>
      </Menu.Item>
      {login?.user?.role !== 'cajero' ? null : (
        <Menu.Item icon={!topMenu && <FeatherIcon icon="dollar-sign" />} key="caja">
          <NavLink onClick={toggleCollapsed} to={`${path}/caja`}>
            Caja
          </NavLink>
        </Menu.Item>
      )}

      {!topMenu && login?.user?.role === 'admin' && <p className="sidebar-nav-title">Administración</p>}
      {login?.user?.role !== 'admin' ? null : (
        <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="users">
          <NavLink onClick={toggleCollapsed} to={`${path}/usuarios`}>
            Usuarios
          </NavLink>
        </Menu.Item>
      )}

      {login?.user?.role !== 'admin' ? null : (
        <Menu.Item icon={!topMenu && <FeatherIcon icon="package" />} key="products">
          <NavLink onClick={toggleCollapsed} to={`${path}/products`}>
            Productos
          </NavLink>
        </Menu.Item>
      )}
      {login?.user?.role !== 'admin' ? null : (
        <Menu.Item icon={!topMenu && <FeatherIcon icon="trending-up" />} key="sells">
          <NavLink onClick={toggleCollapsed} to={`${path}/ventas`}>
            Ordenes Historial
          </NavLink>
        </Menu.Item>
      )}
      {login?.user?.role !== 'admin' ? null : (
        <Menu.Item icon={!topMenu && <FeatherIcon icon="star" />} key="clients">
          <NavLink onClick={toggleCollapsed} to={`${path}/clients`}>
            Clientes / Embajadores
          </NavLink>
        </Menu.Item>
      )}
      {login?.user?.role !== 'admin' ? null : (
        <Menu.Item icon={!topMenu && <FeatherIcon icon="dollar-sign" />} key="cortes">
          <NavLink onClick={toggleCollapsed} to={`${path}/cortes`}>
            Cierre Caja Historial
          </NavLink>
        </Menu.Item>
      )}
      {login?.user?.role === 'pantalla' ? null : (
        <Menu.Item icon={!topMenu && <FeatherIcon icon="credit-card" />} key="cards">
          <NavLink onClick={toggleCollapsed} to={`${path}/cards`}>
            Tarjetas
          </NavLink>
        </Menu.Item>
      )}
      {login?.user?.role !== 'admin' ? null : (
        <Menu.Item icon={!topMenu && <FeatherIcon icon="rotate-cw" />} key="recharges">
          <NavLink onClick={toggleCollapsed} to={`${path}/recargas`}>
            Recargas Historial
          </NavLink>
        </Menu.Item>
      )}
      {login?.user?.role === 'pantalla' ? null : (
        <Menu.Item icon={!topMenu && <FeatherIcon icon="settings" />} key="configuration">
          <NavLink onClick={toggleCollapsed} to={`${path}/configuracion`}>
            Configuración
          </NavLink>
        </Menu.Item>
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
