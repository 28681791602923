import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Modal, Table, Form, Input, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../page-headers/page-headers';
import { Main, TableWrapper, BasicFormWrapper, AddUser } from '../../container/styled';
import { Button } from '../buttons/buttons';
import { Cards } from '../cards/frame/cards-frame';
import { createClient } from '../../redux/clients/actionCreator';

const ClientsTable = ({ pageNumber, onChangePageNumber }) => {
  const { filterClients, clients, loadingCreate, total } = useSelector(state => {
    return {
      filterClients: state.clients.filterClients,
      clients: state.clients.clients,
      loadingCreate: state.clients.loadingCreate,
      total: state.clients.totalResults,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [state, setState] = useState({
    notData: filterClients,
    selectedRowKeys: [],
    update: {},
    modalVisible: false,
    idToUpdate: '',
  });

  const handleOnChangePage = params => {
    onChangePageNumber(params);
  };

  const handleCancel = () => {
    setState({ ...state, modalVisible: false });
  };

  const handleOk = data => {
    dispatch(createClient(data));
  };

  const toggleModal = () => {
    setState({ ...state, modalVisible: !state.modalVisible });
  };

  const dataSource = [];
  if (clients.length) {
    let iteareProp = clients;
    if (filterClients?.length > 0) iteareProp = filterClients;
    iteareProp.map((value, key) => {
      const { id, name, cellphone, email, discount } = value;

      return dataSource.push({
        key: key + 1,
        id: <span className="client-id">{id.substring(8)}</span>,
        name: <span className="name">{name}</span>,
        cellphone: <span className="cellphone">{cellphone}</span>,
        email: <span className="email">{email}</span>,
        discount: <span className="discount">{discount}%</span>,
      });
    });
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Telefono',
      dataIndex: 'cellphone',
      key: 'cellphone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },

    {
      title: 'Descuento',
      dataIndex: 'discount',
      key: 'discount',
    },
  ];

  return (
    <>
      <PageHeader
        ghost
        title="Clientes"
        buttons={[
          <div key="1" className="page-header-actions">
            <Button className="btn-add_new" size="default" key="1" type="primary" onClick={toggleModal}>
              <FeatherIcon icon="user-plus" size={14} /> Agregar Cliente
            </Button>
          </div>,
        ]}
      />
      <Main>
        <Modal title="Informacion del cliente" visible={state.modalVisible} footer={null} onCancel={handleCancel}>
          <div className="project-modal">
            <BasicFormWrapper>
              <AddUser>
                <Form form={form} name="client" onFinish={handleOk}>
                  <Form.Item label="Nombre" name="name" rules={[{ required: true, message: 'Ingresa un nombre' }]}>
                    <Input placeholder="Nombre del cliente" />
                  </Form.Item>

                  <Form.Item
                    label="Telefono Valido"
                    name="cellphone"
                    rules={[{ required: true, message: 'Ingresa un telefono valido', type: 'string' }]}
                  >
                    <Input placeholder="6682341010" />
                  </Form.Item>

                  <Form.Item
                    label="Email Valido"
                    name="email"
                    rules={[{ required: true, message: 'Ingresa un email valido', type: 'email' }]}
                  >
                    <Input placeholder="nombre@ejemplo.com" />
                  </Form.Item>

                  <Form.Item
                    name="discount"
                    label="Descuento"
                    rules={[
                      {
                        required: true,
                        message: 'Ingresa el porcentaje de descuento',
                      },
                      {
                        pattern: /^[1-9][0-9]?$|^100$/,
                        message: 'Ingresa un valor entre 1 y 100',
                      },
                    ]}
                  >
                    <Input placeholder="5%" />
                  </Form.Item>
                  {loadingCreate ? (
                    <Spin />
                  ) : (
                    <Button htmlType="submit" size="default" type="primary" key="submit">
                      Agregar Nuevo Cliente
                    </Button>
                  )}
                </Form>
              </AddUser>
            </BasicFormWrapper>
          </div>
        </Modal>
        <Cards headless>
          <Row gutter={15}>
            <Col md={24}>
              <TableWrapper className="table-order table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{
                    current: pageNumber,
                    onChange: handleOnChangePage,
                    total,
                  }}
                />
              </TableWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

ClientsTable.propTypes = {
  pageNumber: PropTypes.number,
  onChangePageNumber: PropTypes.func,
};
export default ClientsTable;
